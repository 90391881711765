import url from '../../../url.json';

export const getCoupons = (callback) => {
    return async (dispatch) => {
        fetch(`${url.url}/api/coupons/public`)
        .then(res=> res.json())
        .then(resJson => {
            dispatch({type : "LOAD_COUPONS", coupons : resJson.Items})
            typeof callback === 'function' && callback();
        })
        .catch(err => console.error("Error fetching coupons", err))
    }
}