import React, {Component} from 'react';
import { updateQty } from '../store/actions/cart/updateQty';
import {connect} from 'react-redux'
import { deleteFromCart } from '../store/actions/cart/deleteFromCart';

class QuantityPicker extends Component {
    
    constructor(props) {
        super(props)
        this.state = {qty : 1}
    }
   

    handleIncrement = () => {
        this.setState({ qty : this.state.qty + 1}, () => {
            this.props.updateQty(this.props.id,this.state.qty)
        })
    }

    handleDecrement = () => {
        if (this.state.qty>1){
            this.setState({ qty : this.state.qty - 1}, () => {
                this.props.updateQty(this.props.id,this.state.qty)
            })
        }else {
            this.props.del(this.props.id)
        }
    }

    componentDidMount() {
        this.setState({
            qty : this.props.qtyInCart ? this.props.qtyInCart.qty : 1
        })
    }
    componentWillReceiveProps(newProps){
        newProps.qtyInCart && this.setState({qty : newProps.qtyInCart.qty})
    }
        
    render(){
        return (
            <div className="quantityPicker d-flex flex-row border border-dark rounded">
                <div role="button" className="w-25 text-center border-right border-dark border-top-0 border-left-0 border-bottom-0 bg-transparent" onClick={this.handleDecrement}><strong>-</strong></div>
                <div className="w-50 py-1 text-center m-auto"><h5 className="m-0">{this.state.qty}</h5></div>
                {this.props.productQuantity > 0 && this.props.qtyInCart.qty < this.props.productQuantity
                    ?
                        <div role="button" className="w-25 text-center border-left border-dark border-top-0 border-right-0 border-bottom-0 bg-transparent"onClick={this.handleIncrement}><strong>+</strong></div>
                    :
                        <div role="button" className="w-25 text-center border-left border-dark border-top-0 border-right-0 border-bottom-0 bg-transparent" disabled>+</div>
                }
                
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateQty : (id,qty) => dispatch(updateQty(id,qty)),
        del : (id) => dispatch(deleteFromCart(id))
    }
}

const mapStatetoProps = (state,ownProps) => {
    return {
        qtyInCart : state.cart.find(item => item.id === ownProps.id)
        
    }
}
export default connect(mapStatetoProps,mapDispatchToProps)(QuantityPicker)