import React from 'react'

export default function AddrItem(props) {


    return (
        <div className="row my-3">
            <input
                className="col-1 align-self-center"
                type="radio"
                value={props.address}
                name="address"
                checked={props.selectedAddressId === props.address.id} 
                onChange={event => props.handleChange(props.address)}
            />
            <div className="col p-3 mr-3 rounded" id="addrItem" style={{background:"#F5F5F5"}}>
                <h4 className="font-weight-bold">{props.address.fullName}</h4>
                <h5 className="mb-0">{props.address.line1}</h5>
                <h5 className="mb-0">{props.address.line2}</h5>
                <h5 className="mb-0">{props.address.city},{props.address.state}</h5>
                <h5>{props.address.pincode}</h5>
                <h5 className="mb-0">Mobile no: {props.address.mobile}</h5>
                <h5 className="mb-0">Email: {props.address.email}</h5>
            </div>
        </div>
    )
}
