export const updateQty = (id,qty) => {
    return (dispatch,getState) => {
        //update in db
        dispatch(
            {
                type : "UPDATE_QTY",
                qty : qty,
                id : id
            }
        )
    }
}