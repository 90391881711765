const initState = {
    error : false,
    noAddrSelected : false,
    emptyCart : false,
    total : 0,
    subTotal : 0,
    delCharge : 0,
    discount : 0,
    coupon : '',
    couponState : undefined,
    couponMsg : '',
    prodUnavailable : false,
    paymentMode : 'PREPAID',
    sodexoMode : undefined,
    captchaStatus : 0,
    servicable : undefined,
    showCODModal : false,
    coupons : []
}

const checkoutReducer = (checkout=initState, action) => {
    switch(action.type){
        case 'SET_ERROR': 
            return {...checkout, error : action.errorState}
        case 'SET_NO_ADDR_SELECTED':
            return {...checkout, noAddrSelected : action.state}
        case 'SET_EMPTY_CART':
                return {...checkout, emptyCart : action.state}
        case 'SET_PROD_UNAVAILABLE':
                return {...checkout, prodUnavailable : action.state}
        case 'SET_TOTAL':
            return {...checkout, total : action.amt}
        case 'SET_SUB_TOTAL':
            return {...checkout, subTotal : action.amt}
        case 'SET_DEL_CHARGE':
            return {...checkout, delCharge : action.amt}
        case 'SET_DISCOUNT':
            return {...checkout, discount : action.amt}
        case 'SET_PAYMENT_MODE':
                return {...checkout, paymentMode : action.mode}
        case 'SET_PAYMENT_STATUS':
            return {...checkout, paymentStatus : action.status}
        case 'SET_SODEXO_MODE':
            return {...checkout, sodexoMode : action.sodexoMode}
        case 'SET_COUPON':
                return {...checkout, coupon : action.cpn}
        case 'SET_COUPON_STATE':
                return {...checkout, couponState : action.state}
        case 'SET_COUPON_MSG':
                return {...checkout, couponMsg : action.msg}
        case 'RESET':
            return initState
        default:
            return checkout
    }
}

export default checkoutReducer