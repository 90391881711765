import React from 'react'
import { Slide } from 'react-slideshow-image';
import './Banner.css'
const properties = {
    arrows: false,
    pauseOnHover: true,
};

function Banner(props) {
    return (
        <div className="slide-container">
            <Slide {...properties} indicators = {props.indicators} infinite={props.urls.length > 1} transitionDuration={props.transitionDuration} duration={props.duration}>
                {props.urls.map((url) => (
                    <div className="each-slide" key={url}>
                        <img src={url}/>
                        {/* <div style={{ backgroundImage: `url(${url})` }} /> */}
                    </div>
                ))}
            </Slide>            
        </div>
    )
}


export default Banner