import React, {useEffect, useState} from 'react'
import url from '../../url.json'
import {connect} from 'react-redux'
import CouponItem from './CouponItem'
import Coupons from './Coupons'

function CouponCatalog(props) {

    useEffect(()=>{
        // console.log("Coupons", props.coupons)
    })
    return (
        <div>
            <Coupons applyCoupon={props.applyCoupon}/>
            <div id="couponContainer">
                {props.authenticated && props.coupons &&
                props.coupons.map((coupon, index)=>{
                    return <CouponItem coupon={coupon} applyCoupon={props.applyCoupon} index={index}/>
                })
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        coupons : state.coupons,
        authenticated : JSON.stringify(state.profile) === "{}" ? false : true
    }
}

export default connect(mapStateToProps, null)(CouponCatalog)
        

