import url from '../../url.json'

export const addToOrders = (order, callback) => {
    return async (dispatch) => {

        console.log("--adding order to db API--")
        fetch(`${url.url}/api/orders/newOrder`, {
            method : "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body : JSON.stringify({
                order : order
            })
        })
        .then(res => {
            if (res.status === 200) return res.json()
            console.log("Failed to add new order");
            throw new Error("failed to add order to profile");
        })
        .then(resJson => {
            if (resJson.error){
                console.log("error adding order to orders")
                return
            }else{
                console.log("Added Orders to db")
                callback();
                dispatch({
                    type : "ADD_TO_ORDERS",
                    order
                })
                
            }
            return
        })
        .catch(error => {
            console.log("Error adding order to db", error);
        })
    }
}