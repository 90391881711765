import React, { Component } from 'react'
import Banner from "../Home/Banner/Banner"
import FeaturedContainer from '../Products/FeaturedContainer'
import { Spinner } from 'react-bootstrap'
import url from '../../../url.json'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import CatExplorer from './CatExplorer/CatExplorer'
import { connect } from 'react-redux'
import { getCoupons } from '../../../store/actions/coupons/getCoupons'

export class Home extends Component {

    constructor(props){
        super(props);
        this.state = {
            featuredItems : [],
            loading : true,
            couponBanners : []
        }
    }

    

    async componentDidMount(){
        this.props.getCoupons(() => {
            this.setState({
                    couponBanners :  this.props.coupons.filter(cpn=>cpn.hasBanner).sort((a,b)=>{
                        if ( a.bannerIdx < b.bannerIdx ){
                            return -1;
                        }
                        if ( a.bannerIdx > b.bannerIdx ){
                            return 1;
                        }
                        return 0;
                    })
                    .map(cpn => cpn.bannerLink)
            })
        })
       

        const featuredProducts = axios.get(`${url.url}/api/catalog/featured`)
        Promise.all([featuredProducts])
            .then(values => {
                this.setState({featuredItems : values[0].data.Items.slice(0,8).sort(function(a,b){
                    if ( a.name < b.name ){
                        return -1;
                      }
                      if ( a.name > b.name ){
                        return 1;
                      }
                      return 0;
                })})
                this.setState({loading : false})
            })
            .catch(err=>{
            })
    }


    render() {
        return (
            <div>
                <div className="mt-2 mb-2">
                    {this.state.couponBanners.length > 0 &&
                        <Banner urls={this.state.couponBanners} indicators={false} transitionDuration={500} duration={4000}/>
                    }
                </div>
                
                <Banner urls={['https://images-tnb.s3.ap-south-1.amazonaws.com/main/banners/1.1.png', 
                    'https://images-tnb.s3.ap-south-1.amazonaws.com/main/banners/2.1.png',
                    'https://images-tnb.s3.ap-south-1.amazonaws.com/main/banners/3.1.png',
                    'https://images-tnb.s3.ap-south-1.amazonaws.com/main/banners/4.1.png'
                    ]} indicators={true} transitionDuration={500} duration={3000}/>

                <div className="d-md-none">  
                    <h2 className="my-2 mx-md-4 text-center">
                        Browse by Category
                    </h2>
                    <CatExplorer />
                </div>
                
                <div className="mb-4">
                    <Banner urls={['https://images-tnb.s3.ap-south-1.amazonaws.com/main/banners/bank-offers.png']} indicators={false}/>
                </div>
                
                <h2 className="my-2 mx-md-2 text-center">
                    Our Featured Products
                </h2>
                {/* <Products items = {this.state.featuredItems}/> */}
                {this.state.loading 
                    ?
                        <div className="loadingDiv"><Spinner className="spinner" animation="border" variant="success"/></div>
                    :
                        <FeaturedContainer items={this.state.featuredItems} loading={this.state.loading}/>
                }

                <div className="d-none d-md-block">
                    <h2 className="my-2 mx-md-4 text-center">
                        Browse by Category
                    </h2>
                    <CatExplorer/>
                </div>
                <div className="d-none d-md-flex row mt-2">
                    <div className="col-12 col-md-6" style={{padding:"80px 40px"}}>
                        <h4>Our Story</h4>
                        <p >
                        The Nature’s Bowl took its roots when two motivated, highly driven childhood friends decided to bring a positive revolution in the food retail market. We were surprised at how everyone was overlooking problems of the modern world –
                        Young adults are contracting terminal diseases and experiencing extreme levels of fatigueness and depression more than ever. This was in strong contrast to our forefathers who were fit and lively even in their late lean years.<br />
                        Who was at fault?
                        </p>
                        <NavLink className="m-0 p-0 border-0 bg-transparent text-dark" to="/aboutus"><button className="addBtn w-50 p-1">Read More</button></NavLink>
                    </div>
                    <div className="col-12 col-md-6" style={{padding:"80px 40px"}}>
                        <img src='https://images-tnb.s3.ap-south-1.amazonaws.com/main/homePage_Images/farmer-holds-rice-hand+1.png' alt="" style={{width:"100%", margin:"auto"}} />
                    </div> 
                </div>
                <div className="d-md-none mt-2">
                    <div>
                        <img src="https://images-tnb.s3.ap-south-1.amazonaws.com/main/homePage_Images/farmer-holds-rice-hand+1.png" alt="" style={{height:"100%", width:"100%", padding:"10%"}} />
                    </div>
                    <div style={{padding:"50px 30px"}}>
                        <h4>Our Story</h4>
                        <p>
                        The Nature’s Bowl took its roots when two motivated, highly driven childhood friends decided to bring a positive revolution in the food retail market. We were surprised at how everyone was overlooking problems of the modern world –
                        Young adults are contracting terminal diseases and experiencing extreme levels of fatigueness and depression more than ever. This was in strong contrast to our forefathers who were fit and lively even in their late lean years.<br />
                        Who was at fault?
                        </p>
                        <NavLink className="m-0 p-0 border-0 bg-transparent text-dark" to="/aboutus"><button className="addBtn w-50 p-1">Read More</button></NavLink>
                    </div>
                    
                </div>

                <div className="row mt-3">
                    <div className="col-12 col-md-6 p-5">
                        <img src="https://images-tnb.s3.ap-south-1.amazonaws.com/main/homePage_Images/FAVPNG_organic-food-india-organic-organic-certification-organic-farming-agriculture_Yt4Gv7Pm.png" alt="Certificate" style={{width:"100%", height:"100%", padding:"10%"}}/>
                    </div>
                    <div className="col-12 col-md-6" style={{padding:"50px 40px"}}>
                        <h4 className="mb-4">Certified by National Programme for Organic Production (NPOP)</h4>
                        <p>The accreditations under NPOP have been accepted by foreign countries for unprocessed food like The US,European Union etc according to their country standards as well.</p>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        coupons : state.coupons
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      getCoupons : (cb) => dispatch(getCoupons(cb))
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Home)
