import React, { Component } from 'react'
import { deleteFromCart } from '../../../store/actions/cart/deleteFromCart'
import {connect} from 'react-redux'
import './Cart.css'
import QuantityPicker from '../../QuantityPicker'

export class CardforCart extends Component {
  

    handleDelete = () => {
        this.props.del(this.props.item.id)
    }

    render() {
        return (
            <div className="CardForCart p-0 shadow-sm m-2 rounded">
                <img className="rounded-left" src={this.props.item.imageUrl} alt="Product Image"/>
                <div className="ml-2 p-1 position-relative">
                    <h4 className="m-0 text-left" style={{height:"42%", overflow:"hidden"}}>{this.props.item.name}</h4>
                    <h4 className="m-0 text-left">{this.props.item.size} {this.props.item.unit}</h4>
                    {/* <div className="d-flex justify-content-between quantity pr-2">
                        <QuantityPicker id={this.props.item.id}/>
                        <h6 className="mb-0 mt-1">Rs. {this.props.item.price}</h6>
                    </div> */}
                    <div className="d-flex justify-content-between quantity pr-2">
                        <div className="col-7 p-0 mr-2"><QuantityPicker id={this.props.item.id} productQuantity={this.props.item.quantity}/></div>
                        <h3 className="col-5 m-auto">
                            &#8377;
                            {this.props.item.discountedPrice < this.props.item.price ? this.props.item.discountedPrice : this.props.item.price}
                            </h3>
                    </div>
                </div>
                {/* <h3 >{this.props.item.name}</h3>
                <div role="button" className="btn btn-danger" onClick={this.handleDelete}>X</div>
                <QuantityPicker id={this.props.item.id}/> */}
            </div>
            
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        del : (id) => dispatch(deleteFromCart(id))
    }
}


export default connect(null,mapDispatchToProps)(CardforCart)
