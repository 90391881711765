import url from  '../url.json'

export const updateWishlist = (userId, wishlist) => {

    console.log("Now sending wishlist to db")
    //fetch post call with new wishlist

    fetch(`${url.url}/api/user/wishlist`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body : JSON.stringify({
            userEmail : userId,
            wishlist : wishlist
            })
        })
        .then(res => {
            if (res.status === 200) return res.json()
        })
        .then(resJson => {
            if (resJson.error){
                console.error("Error updating wishlist")
            }else{
                console.log("Updated wishlist in db")
            }
        })
        .catch(error => {
            console.error("Error updating wishlist", error);
        })
}