import React from 'react'
import '../../../Side/Cart/Cart.css'
export default function OrdersItemCard(props) {
    return (


        <div className="ProductCardCheckout shadow-sm mb-2">
            <img className="p-0" src={props.item.imageUrl} alt=""/>
            <div className="px-3 p-2 position-relative">
                <h5 className="font-weight-normal">{props.item.name}</h5>
                <h6 className="font-weight-normal">{props.item.size}{props.item.unit.toUpperCase()}</h6>
                <div className="d-flex justify-content-between">
                    <p>qty {props.item.qty}</p>
                    <p>&#8377;{props.item.discountedPrice*props.item.qty}</p>
                </div>
            </div>
        </div>
        
        // <div className="d-flex shadow-sm mb-2" style={{maxHeight:"120px"}}>
        //     <img className="p-0" style={{width:"18%"}} src={props.item.imageUrl} alt=""/>
        //     <div className="px-3 w-100 p-2">
        //         <h5 className="font-weight-normal">{props.item.name}</h5>
        //         <h6 className="font-weight-normal">{props.item.size}{props.item.unit.toUpperCase()}</h6>
        //         <div className="d-flex justify-content-between">
        //             <p>qty {props.item.qty}</p>
        //             <p>&#8377;{props.item.discountedPrice*props.item.qty}</p>
        //         </div>
        //     </div>
        // </div>

    )
}
