

const initState = []

const ordersReducer = (orders=initState,action) => {
    switch(action.type){
        case "ADD_TO_ORDERS":
            return [...orders, action.order]
        case "SET_ORDERS":
            return action.orders
        default:
            return orders
    }
}

export default ordersReducer