// import LOGIN_USER from '../actions/loginUser'
const initState = {}

const profileReducer = (profile=initState, action) => {
    
    switch(action.type){
        case "LOGIN_USER" :
            return action.user
        case "SAVE_PROFILE_DETAILS":
            return {
                ...profile,
                fname : action.fname,
                lname : action.lname,
                email : action.email,
                mobile : action.mobile
            }
        case "ADD_TO_WISHLIST":
            return {
                ...profile,
                wishlist : [...profile.wishlist, action.item]
            }
        case "DELETE_FROM_WISHLIST":
            return {
                ...profile,
                wishlist : profile.wishlist.filter(item => item.id !== action.id)
            }
        case "ADD_ADDRESS":
            return {
                ...profile,
                addresses : [...profile.addresses,action.item]
            }
        case "DELETE_ADDRESS":
            return {
                ...profile,
                addresses : profile.addresses.filter(item => item.id !== action.id)
            }
        case "SELECT_DEFAULT_ADDRESS":
            return{
                ...profile,
                addresses : profile.addresses.map((item)=> {
                    if(item.id === action.id){
                        item.default = true
                    }else {
                        item.default = false
                    }
                    return item
                })
            }
        case "INC_COUPON_USE":
            return{...profile, coupons : action.updatedCoupons}
        case "SET_SODEXO_SOURCE_ID":
            return {...profile, sodexoSourceId:action.sourceId}
        default:
            return profile
    }
    

}

export default profileReducer