import store from '../configureStore';
import url from '../../url.json'
export const addAddress = (item, callback) => {
    return async (dispatch, getState) => {
        console.log("inside add addr action", store.getState().profile.PK)
        fetch(`${url.url}/api/user/address/add`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
              },
            body : JSON.stringify({
                userPK : store.getState().profile.PK,
                addr : item
            })
        })
        .then(res => {
            if (res.status === 200) return res.json()
            console.log("Failed to add address");
            throw new Error("failed to add address");
        })
        .then(resJson => {
            if (resJson.error){
                return;
            }else{
                dispatch({ type : "ADD_ADDRESS", item})
                typeof callback === 'function' && callback();
            }
        })
        .catch(error => {
            console.log("Error adding addr", error);
          });
        
    }
}