import React, {useState, useEffect} from 'react'
import { addPaymentDetails } from '../store/actions/addPaymentDetails'
import { connect } from 'react-redux'
import store from '../store/configureStore'
import { addToOrders } from '../store/actions/addToOrders'
import { emptyCart } from '../store/actions/cart/emptyCart'
import { updateQuantityInDB } from '../apis/updateQuantityinDB'
import {resetCurrOrder} from '../store/actions/resetCurrOrder'
import { Redirect } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { setSodexoSourceId } from '../store/actions/setSodexoSourceId'

function OrderConfirmation (props) {

    let history = useHistory();

    useEffect(()=>{

        const query = new URLSearchParams(props.location.search)
        const payment_mode = query.get('payment_mode');
        const rp_order_id = query.get('rp_order_id');
        const rp_payment_id = query.get('rp_payment_id');
        const rp_signature = query.get('rp_signature');
        const soxedo_req_id = query.get('sodexo_req_id')

        if(payment_mode === "postpaid"){
            props.addToOrders(props.currOrder, () => {
                console.log("New redirecting to thankyou page")
                history.push('/thankyou/postpaid')
            })
        }else if(payment_mode === 'sodexo'){
            !props.user.sodexoSourceId && props.setSodexoSourceId(soxedo_req_id,props.user.PK)
            props.addToOrders(props.currOrder, () => {
                history.push('/thankyou/postpaid')
            })
        }else{
            //adding razorpay details to the order, then adding order to the db 
            props.addPaymentDetails(rp_order_id,rp_payment_id,rp_signature, () => {
                props.addToOrders(props.currOrder, () => {
                    console.log("New redirecting to thankyou page")
                    history.push('/thankyou/prepaid')
                })
            })
        }
    },[])


        
    return(
        <div className="text-center">
            <h1>
                Confirming Order<br/>
                Please don't refresh.
            </h1>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        user : state.profile,
        currOrder : state.currOrder,
        cart : state.cart,
        orders : state.orders
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addPaymentDetails : (razorpay_order_id,razorpay_payment_id,razorpay_signature,callback) => dispatch(addPaymentDetails(razorpay_order_id,razorpay_payment_id,razorpay_signature,callback)),
        addToOrders : (order, callback) => dispatch(addToOrders(order, callback)),
        emptyCart : ()=> dispatch(emptyCart()),
        resetCurrOrder : ()=> dispatch(resetCurrOrder()),
        setSodexoSourceId : (requestId, userId) => dispatch(setSodexoSourceId(requestId, userId))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(OrderConfirmation)