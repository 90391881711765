import React, { Component } from 'react'

export class Contact extends Component {
    render() {
        return (
            <div id="landing">
                <h1>Contact</h1>
            </div>
        )
    }
}

export default Contact
