import url from '../../url.json'

export const addPaymentDetails = (razorpay_order_id,razorpay_payment_id,razorpay_signature, callback) => {
    return (dispatch) => {


        dispatch({
            type : "ADD_PAYMENT_DETAILS",
            payload : {razorpay_order_id,razorpay_payment_id,razorpay_signature}
        })
        typeof callback === 'function' && callback();
    }
}