import store from '../configureStore';
import url from '../../url.json'

export const makeDefault = (id) => {
    return (dispatch) => {
        fetch(`${url.url}/api/user/address/default`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body : JSON.stringify({
                userPK : store.getState().profile.PK,
                addresses : store.getState().profile.addresses,
                id : id
            })
        })
        .then(res => {
            if (res.status === 200) return res.json()
            console.log("Failed to change default");
            throw new Error("failed to default");
        })
        .then(resJson => {
            if (resJson.error !== true){
                return;
            }
        })
        .catch(error => {
            console.log("Error changing default", error);
        })
        dispatch({
            type : "SELECT_DEFAULT_ADDRESS",
            id
        })

    }
}
