import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { deleteAddress } from '../../../../store/actions/deleteAddress'
import {connect} from 'react-redux'
import { makeDefault } from '../../../../store/actions/makeAddDefault'
import deleteIcon from '../../../../Icons/trash-2.svg'

class AddrItemClass extends Component {
    constructor(props){
        super(props)
        this.state = {
            default : this.props.addr[0].default
        }
    }

    handleDelete = () => {
        this.props.del(this.props.addr[0].id,this.props.idx)
    }

    handleDefault = () => {
        this.setState({ default : true }, () => {
        this.props.makeDefault(this.props.addr[0].id)
        })
    }

    componentWillReceiveProps(newProps){
        console.log("This runs now", newProps.addr[0]);
        newProps.addr[0] && this.setState({default : newProps.addr[0].default})
    }
    
    render() {
        
        return (
            <div className="d-block mb-3 py-3 px-3 shadow bg-light rounded">
                <Row>
                    <Col xs={10}>
                        <input type="radio" checked={this.state.default} onChange={this.handleDefault}/>
                        <label className="ml-2"><i>Default Address</i></label>
                    </Col>
                    <Col xs={2}>
                        <button className="border-0 bg-transparent float-right" onClick={this.handleDelete}><img src={deleteIcon} alt="Del"/></button>
                    </Col>
                </Row>
                <h4 className="font-weight-bold">{this.props.addr[0].fullName}</h4>
                <h5 className="m-0">{this.props.addr[0].line1}</h5>
                <h5 className="m-0">{this.props.addr[0].line2}</h5>
                <h5 className="m-0">{this.props.addr[0].city},{this.props.addr[0].state}</h5>
                <h5 className="m-0">{this.props.addr[0].pincode}</h5>
                <br />
                <h5 className="m-0">Mobile no: {this.props.addr[0].mobile}</h5>
            </div>

                // <Row>
                //     <Col xs={10}>
                //         <input type="radio" checked={this.state.default} onChange={this.handleDefault}/><label>Default Address</label>
                //         <h4>{this.props.addr[0].fullName}</h4>
                //         <p>{this.props.addr[0].line1}</p>
                //         <p>{this.props.addr[0].line2}</p>
                //         <p>{this.props.addr[0].city},{this.props.addr[0].state}</p>
                //         <p>{this.props.addr[0].pincode}</p>
                //         <br />
                //         <p>Mobile no: {this.props.addr[0].mobile}</p>
                //     </Col>
                //     <Col xs={2}>
                //         <button className="border-0 bg-transparent" onClick={this.handleDelete}><img src={deleteIcon}/></button>
                //         {/* {this.state.default ? <p>this is default</p> : <button onClick={this.handleDefault}>make default</button>} */}
                //     </Col>
                // </Row>
                
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        del : (id,idx) => dispatch(deleteAddress(id,idx)),
        makeDefault : (id) => dispatch(makeDefault(id))
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        addr : state.profile.addresses.filter(item => item.id === ownProps.itemId)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AddrItemClass)
