import React from 'react'
import { connect } from 'react-redux'
import { setCoupon } from '../../store/actions/checkout/setCoupon';

function CouponItem(props) {


    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.coupon.code)
        // console.log(document.getElementById("couponContainer").children[2].children[1].children[1])
        for(var i=0; i < document.getElementById("couponContainer").children.length; i++){
            // console.log(document.getElementById("couponContainer").children[i].children[1].children[1])
            if (i === props.index){
                document.getElementById("couponContainer").children[i].style.borderColor = "#9BD97D"
                document.getElementById("couponContainer").children[i].children[0].children[1].style.display = "block"
            }else{
                document.getElementById("couponContainer").children[i].style.borderColor = "#EEEEEE"
                document.getElementById("couponContainer").children[i].children[0].children[1].style.display = "none"
            }
        }

        props.setCoupon(props.coupon.code, props.applyCoupon)

    }


    return (
        <div className={props.appliedCoupon.toUpperCase() === props.coupon.code.toUpperCase() ? "appliedCoupon my-3" : "couponItem my-3"} role="button" 
            onClick={()=>props.setCoupon(props.coupon.code, ()=>null)}>
            <div className="d-flex justify-content-between w-100">
                <h4><b id="code">{props.coupon.code}</b></h4>
                <i className="mr-md-3 text-success" style={{display:"none"}}>Applied</i>
            </div>
            <h5>{props.coupon.title}</h5>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        appliedCoupon : state.checkout.coupon
    }
}
const mapDispatchToProps = (dispatch) => {
    return{
        setCoupon : (cpn, cb) => dispatch(setCoupon(cpn, cb))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CouponItem)
