import React from 'react'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';
import './Header.css'
import logo from '../../Logo/tnb-logo 1.png'
import Search from './SearchFunc'

export default function Header(props) {

    const isPhone = useMediaQuery({ query: '(max-device-width:1150px)' });

    const handleLogoClick = () => {
        if (isPhone) {
            document.getElementById("landing").style.display = "block";
            document.getElementById("profile").style.display = "none";
            document.getElementById("side").style.display = "none";
            document.getElementById("cart").style.display = "none";
            document.getElementById("saved").style.display = "none";
        }
    };
    return (
        <div>
            <div className="px-1 px-md-5 miniHeader">
                <div className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active my-auto">
                            <p className="text-white text-center font-weight-bold mb-0 py-1">10% OFF ON ALL ORDERS - USE CODE <strong>TNB10</strong></p>
                        </div>
                        <div className="carousel-item my-auto">
                            <p className="text-center text-white font-weight-bold mb-0 py-1">PAN INDIA DELIVERY</p>
                        </div>
                        <div className="carousel-item my-auto">
                            <p className="text-center text-white font-weight-bold mb-0 py-1">FREE DELIVERY ON ALL ORDERS ABOVE &#8377;799</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row m-0 px-1 px-md-5 header">
                <div className="col-5" onClick={handleLogoClick}>
                    <NavLink to="/home" className="navbar-brand" role="button" id="homebtn">
                        <img src={logo} className="logo" alt="TNB" />
                    </NavLink>
                </div>
                <div className="col-7 pl-2">
                    <Search />
                </div>
            </div>
        </div>

    )

}

