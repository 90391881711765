const initState = {
}

const shippingInfoReducer = (shippingInfo = initState, action) => {
    switch (action.type){
        case "SET_NAME_SHIPPING":
            return {
                ...shippingInfo,
                fullName : action.name
            }
        case "SET_MOBILE_SHIPPING":
            return {
                ...shippingInfo,
                mobile : action.mobile
            }
        case "SET_EMAIL_SHIPPING":
            return {
                ...shippingInfo,
                email : action.email
            }
        case "SET_LINE1":
            return {
                ...shippingInfo,
                 line1: action.line1
            }
        case "SET_LINE2":
            return {
                ...shippingInfo,
                line2: action.line2
            }
        case "SET_PINCODE":
            return {
                ...shippingInfo,
                pincode: action.pincode
            }
            case "SET_CITY":
        return {
            ...shippingInfo,
            city: action.city
        }
        case "SET_STATE":
            return {
                ...shippingInfo,
                state: action.state
            }
        case "SET_FULL_ADDR":
            return action.addr
            
                
        default:
            return shippingInfo
    }
}

export default shippingInfoReducer