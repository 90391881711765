import { Button, Modal } from 'bootstrap'
import React from 'react'
import OrdersItemProductCard from './OrdersItemProductCard'
import phoneIcon from '../../../../Icons/phone.svg'
import emailIcon from '../../../../Icons/emailBlack.svg'


export default function OrderItem(props) {
    const date = new Date(props.order.createdAt)
    const months = {
        0 : "Jan", 1:"Feb", 2:"Mar", 3:"April", 4:"May", 5:"June", 
        6:"July", 7: "Aug", 8:"Sept", 9:"Oct", 10:"Nov", 11:"Dec"
    }


    return (
        <div className="shadow-sm px-3 pt-0 pb-3 m-md-3">
            <div className="row bg-light p-3 mb-3">
                <div className="col-3">
                    <p className="m-0 font-weight-bold">Ordered on</p> 
                    <p>{date.getDate()} {months[date.getMonth()]}</p>
                </div>
                <div className="col-6">
                    <p className="m-0 font-weight-bold">Order #</p> 
                    <p style={{wordWrap:"break-word"}}>{props.order.orderId || props.order.SK}</p>
                </div>
                <div className="col-3">
                    <div className="float-right">
                        <p className="m-0 font-weight-bold">Order Total</p> 
                        <p>&#8377;{props.order.total}</p>
                    </div>
                </div>
            </div>

            {/* This is the order items container */}
            <div>
                <h4>Items</h4>
                {props.order.items.map(item=>{
                    return <OrdersItemProductCard item={item}/>
                })}
            </div>
            <div className="d-flex mt-4">
                <button className="btn d-none btn-success mr-3">Track Order</button>
                <button className="btn btn-secondary" data-toggle="modal" data-target="#exampleModalCenter">Report Issue</button>
            </div>
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Report an Issue</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>We apologize in advance for any inconvenience. Kindly get in touch with our team and we will be sure to help you!</p>
                        

                        <div>
                            <img src={phoneIcon} alt="Ph" className="mr-2"/>
                            <a href="tel:+917767885222" className="text-dark">
                                <u>+91 7767885222</u>
                            </a>
                        </div>
                        <div>
                            <img src={emailIcon} alt="Email" className="mr-2"/>
                            <a href="mailto:hello@thenaturesbowl.com" className="text-dark">
                                <u>hello@thenaturesbowl.com</u>
                            </a>
                        </div>
                    




                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
