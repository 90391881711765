import React, {useState,useEffect} from 'react'
import { connect } from 'react-redux'
import AddrItem from './AddrItem';
import { Modal } from 'react-bootstrap';
import AddressForm from '../Landing/UserProfile/Addresses/AddressForm';
import { setFullAddr } from '../../store/actions/shippingInfo/setAddress';
import { setNoAddrSelected } from '../../store/actions/checkout/setNoAddrSelected';


function SavedAddresses(props) {
    const [modalVisibility, setModalVisibility] = useState(false);

    useEffect(() => {
        if(JSON.stringify(props.currDeliveryAddr) === '{}'){
            const primaryAddr = props.addresses.find(item => item.default);
            primaryAddr && props.setFullAddr(primaryAddr);
        }
    },[])

    useEffect(() => {
        props.noAddrSelected && !props.addresses.length && openModal();
    }, [props.noAddrSelected])
    const handleChange = (addr) => {
        props.setFullAddr(addr);
        props.setNoAddrSelected(false);
    }

    const openModal = () => {
        setModalVisibility(true)
    }
    const closeModal = () => {
        setModalVisibility(false)
    }


    const addressList = props.addresses.map((item) => {
        return <AddrItem address={item} key={item.id} handleChange={handleChange} selectedAddressId={props.currDeliveryAddr.id}/>
    })

    return (
        <div>
            {addressList}
            <button onClick={openModal} className="ml-5 text-success bg-transparent px-3 py-2 rounded" style={{borderStyle:"dashed", borderColor:"#C8C8C8"}}>
                Add new address
            </button>
            <Modal
                show={modalVisibility}
                onHide={closeModal} 
                centered backdrop="static" 
                keyboard={false}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>New Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddressForm close={closeModal} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        addresses : state.profile.addresses,
        currDeliveryAddr: state.shippingInfo,
        noAddrSelected : state.checkout.noAddrSelected,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setFullAddr : (addr) => dispatch(setFullAddr(addr)),
        setNoAddrSelected : (state) => dispatch(setNoAddrSelected(state))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SavedAddresses)


