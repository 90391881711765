import React, { Component } from 'react'
import {connect} from 'react-redux'
import './Checkout.css'
import QuantityPicker from '../QuantityPicker'
import { deleteFromCart } from '../../store/actions/cart/deleteFromCart'

export class ProductCardCheckout extends Component {
  

    handleDelete = () => {
        this.props.del(this.props.item.id)
    }

    render() {
        return (
            <div className="ProductCardCheckout shadow-sm mb-2 mb-md-3 rounded">
                <img className="rounded-left" src={this.props.item.imageUrl} alt="Product Image"/>
                <div className="CardForCart-body pl-2 pt-1 position-relative">
                    <h3 className="m-0 text-left">{this.props.item.name}</h3>
                    <h3 className="m-0 text-left">{this.props.item.size} {this.props.item.unit.toUpperCase()}</h3>
                    <div className="row justify-content-between quantity pr-3">
                        <div className="col-7 col-md-4"><QuantityPicker id={this.props.item.id} productQuantity={this.props.item.quantity}/></div>
                        <h2 className="col-5 col-md-8 my-0 p-0 p-md-1 text-right">
                            &#8377; 
                            {this.props.item.price > this.props.item.discountedPrice ? this.props.item.discountedPrice : this.props.item.price}
                        </h2>
                    </div>
                </div>
                {/* <h3 >{this.props.item.name}</h3>
                <div role="button" className="btn btn-danger" onClick={this.handleDelete}>X</div>
                <QuantityPicker id={this.props.item.id}/> */}
            </div>
            
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        del : (id) => dispatch(deleteFromCart(id))
    }
}


export default connect(null,mapDispatchToProps)(ProductCardCheckout)
