import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux'
import './Navbar.css';
import heart from '../../Icons/heart.svg'
import cart from '../../Icons/shopping-cart.svg'
import user from '../../Icons/user.svg'

function NavbarFunc(props) {

    // console.log("these are the navbar props", props);
    let username = JSON.stringify(props.user) !== "{}" ? "Profile" : "Login"
   
    let categories = null;
    const categoryList = ["Flours and Daliya","Cereal and Pulses","Oils",
    "Spices", "Sweeteners", "Salts", "Tea", "Superfoods"]

    const isPhone = useMediaQuery({ query : '(max-device-width:1150px)'});
    const switchDisplay = (e) => {
        e.preventDefault();
        console.log("The display is a phone screen", isPhone);
        // console.log("target id" ,e.target)
        switch (e.target.id) {
            case "profilebtn":
                if (isPhone){
                    document.getElementById("landing").style.display = "none"
                }
                document.getElementById("side").style.display = "block"
                document.getElementById("profile").style.display = "block"
                document.getElementById("cart").style.display = "none"
                document.getElementById("saved").style.display = "none"
                break;

            case "savedbtn":
                if (isPhone){
                    document.getElementById("landing").style.display = "none"
                }
                document.getElementById("side").style.display = "block"
                document.getElementById("profile").style.display = "none"
                document.getElementById("cart").style.display = "none"
                document.getElementById("saved").style.display = "block"
                break; 

            case "cartbtn":
                if (isPhone){
                    document.getElementById("landing").style.display = "none"
                }
                document.getElementById("side").style.display = "block"
                document.getElementById("profile").style.display = "none"
                document.getElementById("cart").style.display = "block"
                document.getElementById("saved").style.display = "none"
                break;
            case "category":
                if (isPhone){
                    document.getElementById("profile").style.display = "none"
                    document.getElementById("cart").style.display = "none"
                    document.getElementById("side").style.display = "none"
                    document.getElementById("saved").style.display = "none"
                    document.getElementById("landing").style.display = "block"
                }
                
                break;
            default:
                break;
        }
    }

    const buildCategoryItem = (category) => {
        const url  = `${props.path}/category/${category}`
        return(
            <div onClick={switchDisplay} key={category}><NavLink className="nav-link text-dark" to={url} id="category" activeStyle={{fontWeight:"bold"}}>{category}</NavLink></div>
        )
    }

    if (categoryList) {
        categories = categoryList && categoryList.map((item) => {
            return buildCategoryItem(item);
        })
    }

    const closeDropdown = () => {
        // document.getElementsByClassName("dropdown-content").style.display = "none"
        document.getElementById("dd_menu").style.display = "none"
    }

    return (
        <nav className="navbar nav navbarCSS d-flex justify-content-between">
{/* 
            <div className="dropdown d-none d-lg-none" >
                <div id="dLabel" className="dropdown-toggle" data-toggle="dropdown">Categories</div>
                    <div id="dd_menu" aria-labelledby="dLabel" className="dropdown-content">
                        {categories}
                    </div>
            </div> */}

            <div class="dropdown d-lg-none">
                <button className="btn dpbtn dropdown-toggle" data-toggle="dropdown">
                    Categories
                </button>
                <div className="dropdown-menu">
                    {categories}
                </div>
            </div>

            <ul className="d-none d-lg-flex mb-0">
                {categories}
            </ul>
            
            <div className="">
                <ul className="d-flex mb-0 justify-content-between list-unstyled nav-tabs border-0 m-0 pl-sm-4">
                    <li className="align-self-center mx-3 ml-md-3 mr-md-4 pb-2 pb-md-2 mb-0">
                        <div className="d-flex justify-content-between">
                            <div className="d-none d-xl-block align-self-center m-0 mr-2" role="button" id="profilebtn" onClick={switchDisplay}>
                                    {username}
                            </div>
                            <img className="align-self-center" src={user} id="profilebtn" alt="P" onClick={switchDisplay}/>
                        </div>
                        {/* <div className="nav-link userNavItem">
                            <div className="row" >
                                <div className="col d-none d-md-block" role="button" id="profilebtn" onClick={switchDisplay}>
                                    {username}
                                </div>
                                <div className="col">
                                    <img src={user} id="profilebtn" onClick={switchDisplay}/>
                                </div>
                            </div>
                        </div> */}
                    </li>
                    <li className="align-self-center mx-3 mx-md-4 pb-2 pb-md-2">
                        <div className="nav-item d-flex justify-content-between">
                            <div className="d-none d-xl-block align-self-center m-0 mr-2" role="button" id="savedbtn" onClick={switchDisplay}>
                                    Saved
                            </div>
                            <img className="align-self-center" src={heart} id="savedbtn" alt="S" onClick={switchDisplay}/>
                        </div>

                        {/* <div className="nav-link userNavItem">
                            <div className="row">
                                <div className="col d-none d-md-block" id="savedbtn" role="button" onClick={switchDisplay}>
                                    Saved
                                </div>
                                <div className="col">
                                    <img src={heart} id="savedbtn" onClick={switchDisplay}/>
                                </div>
                            </div>
                        </div> */}
                    </li>
                    <li className="align-self-center mx-3 ml-md-4 pb-2 pb-md-2">
                        <div className="nav-item d-flex justify-content-between">
                            <div className="d-none d-xl-block align-self-center m-0 mr-2" role="button" id="cartbtn" onClick={switchDisplay}>
                                    Cart
                            </div>
                            <img className="align-self-center" id="cartbtn" src={cart} alt="C" onClick={switchDisplay}></img>
                                <p className="px-2" style={{background: "#90DA7B", position:"relative", top:"0px", right:"10px", borderRadius:"50%"}} id="cartbtn" onClick={switchDisplay}>{props.noOfItems}</p>
                        </div>
                        {/* <div className="nav-link userNavItem">
                            <div className="row">
                                <div className="col d-none d-md-block" id="cartbtn" role="button" onClick={switchDisplay}>
                                    Cart
                                </div>
                                <div className="col">
                                    <img src={cart} id="cartbtn" onClick={switchDisplay}/>
                                </div>
                            </div>
                        </div> */}
                    </li>
                </ul> 
            </div>
        </nav>
    
)
}

const mapStateToProps = (state) => {
    return {
        user : state.profile,
        noOfItems : state.cart.reduce((acc,item)=>{
            return acc + item.qty
        },0),
    }
}
export default connect(mapStateToProps)(NavbarFunc)
