import React, { Component } from 'react'
import { NavLink} from 'react-router-dom'
import { logoutUser } from '../../../store/actions/logoutUser';
import {connect} from 'react-redux'
import "./Profile.css"

export class Profile extends Component {

    handleLogout = () => {
        this.props.logout();
        
    }

    render() {
        //see if there is a profile in the store, if yes, that means the user is logged in
        //directly show the account menu then , show the login page if not logged in
        return (
                <div className="profileMenu">
                    <div className="mb-4 d-flex flex-row justify-content-center">
                        <h1 className="pr-2">Welcome, </h1> 
                        {JSON.stringify(this.props.user.fname) === "" ? "User" : <h1 style={{color:"#54B94D"}}>{this.props.user.fname}</h1>}
                    </div> 
                    <NavLink to='/home/account' className="ml-4 p-3 text-dark nav-link" activeStyle={{fontWeight:"bolder", fontStyle:"oblique"}}>
                        Your Account
                    </NavLink>
   
                    <NavLink to='/home/orders' className="ml-4 p-3 text-dark nav-link" activeStyle={{fontWeight:"bolder", fontStyle:"oblique"}}>
                        Orders
                    </NavLink>
                    <NavLink to='/home/addresses' className="ml-4 p-3 text-dark nav-link" activeStyle={{fontWeight:"bolder", fontStyle:"oblique"}}>
                        Saved Addresses
                    </NavLink>
                    {/* <NavLink to='/home/membership' className="p-3 text-dark nav-link" activeStyle={{backgroundColor:"rgba(117, 117, 117, 0.15)"}}>
                        Membership Plan
                    </NavLink> */}
                    
                    <div role="button" className="d-flex p-3 w-100 align-content-start" style={{position:"absolute", bottom:"15vh"}} onClick={this.handleLogout}>
                        <h5 className="ml-4">Sign Out</h5>
                    </div>
                </div>          
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user : state.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout : () => dispatch(logoutUser())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Profile)
