import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import { Form, FormGroup, FormControl, FormLabel, Button } from 'react-bootstrap';
import store from '../../../store/configureStore'
import {saveProfile} from '../../../store/actions/saveProfile'
import {connect} from 'react-redux'

function Settings (props) {
    const isPhone = useMediaQuery({ query : '(max-device-width:1024px)'});
    if(isPhone){
        if(document.getElementById("landing")){
            document.getElementById("landing").style.display = "block"
        }
        if(document.getElementById("side")){
        document.getElementById("side").style.display = "none"
        }
    }

    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")

    useEffect(()=>{
        store.getState().profile.fname && setFname(store.getState().profile.fname)
        store.getState().profile.lname && setLname(store.getState().profile.lname)
        store.getState().profile.email && setEmail(store.getState().profile.email)
        store.getState().profile.mobile && setMobile(store.getState().profile.mobile)
    },[])

    
    const [unsaved, setUnsaved] = useState(false);



    const handleChange = (event) => {
        switch(event.target.name){
            case "fname":
                setFname(event.target.value);
                setUnsaved(true)
                break
            case "lname":
                setLname(event.target.value);
                setUnsaved(true)
                break
            case "email":
                setEmail(event.target.value);
                setUnsaved(true)
                break
            case "mobile":
                setMobile(event.target.value);
                setUnsaved(true)
                break
            default:
                break
        }
    }
    const onSave = () => {
        // if (typeof email === 'undefined' || email === '' || typeof mobile === 'undefined' || mobile.length !== 10 || isNaN(mobile)){
        //     if (typeof email === 'undefined' || email === ''){
        //         document.getElementById('acEmail').style.borderColor = 'red'
        //     }
        //     if (typeof mobile === 'undefined' || mobile.length !== 10 || isNaN(mobile)){
        //         console.log(typeof mobile);
        //         document.getElementById('acMobile').style.borderColor = 'red'
        //     }
        //     return
        // }
        props.saveProfile(fname,lname,email,mobile)
        setUnsaved(false)
    }



    return (
        <div>
            <h2 className="mb-5">Your Account</h2>
            <div className="p-0 m-md-5 shadow-sm">
                <h3 className="bg-light w-100 m-0 px-3 py-2">Profile Details</h3>
                <Form className="p-5">
                    <FormGroup className="row">
                        <FormLabel className="col-4 col-md-5 text-right" column="md">First name</FormLabel>
                        <FormControl className="col-8 col-md-5 bg-light border-0" id="acFname" type="text" value={fname} name="fname" onChange={handleChange} placeholder="Enter first name .."></FormControl>
                    </FormGroup>
                    <FormGroup className="row">
                        <FormLabel className="col-4 col-md-5 text-right" column="md">Last name</FormLabel>
                        <FormControl className="col-8 col-md-5 bg-light border-0"  id="acLname" type="text" value={lname} name="lname" onChange={handleChange} placeholder="Enter last name .."></FormControl>
                    </FormGroup>
                    <FormGroup className="row">
                        <FormLabel className="col-4 col-md-5 text-right" column="md">Contact email</FormLabel>
                        <FormControl className="col-8 col-md-5 bg-light border-0" id="acEmail" type="email" value={email} name="email" onChange={handleChange} placeholder="Enter email .."></FormControl>
                    </FormGroup>
                    <FormGroup className="row">
                        <FormLabel className="col-4 col-md-5 text-right" column="md">Mobile number</FormLabel>
                        <FormControl className="col-8 col-md-5 bg-light border-0" id="acMobile" type="text" value={mobile} name="mobile" onChange={handleChange} placeholder="Enter mobile number .."></FormControl>
                    </FormGroup>
                    <div className="d-flex align-content-center">
                    {unsaved ? <Button className="btn btn-success btn-lg mx-auto" onClick={onSave}>Save</Button> : null}
                    </div>
                </Form>
            </div>
        </div>
    )
    
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveProfile : (fname,lname,email,phone) => dispatch(saveProfile(fname,lname,email,phone))
    }
}

export default connect(null,mapDispatchToProps)(Settings)
