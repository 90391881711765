import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../Logo/tnb-logo 1.png'

export default function PlainHeader(props) {
    return (
        <div className="px-4 py-1" style={{width:"149px",height:"80px"}}>
            <NavLink to="/home" role="button" className="m-0 p-0">
                <img src={props.logo || logo} className="mx-4 my-2" alt="TNB" style={{width:"155px",height:"60px"}}/>
            </NavLink>
        </div>
    )
}
