import React from 'react'
import b from '../../../Images/features/cf.svg';
import a from '../../../Images/features/nac.svg';
import c from '../../../Images/features/sustainable.png'
import d from '../../../Images/features/lab_tested.png'
import './ProductPage.css'

export default function Features() {
    return (
        <div className="featureContainer">
            <div className="m-2 p-2">
                <div className="d-flex justify-content-center mb-4"><img style={{height:"80px", width:"80px"}} src="https://images-tnb.s3.ap-south-1.amazonaws.com/main/vectors/nochemicals.png" alt=""/></div>
                <div className="px-3"><h3 className="text-center">NO ADDED CHEMICALS</h3></div>
            </div>
            <div className="m-2 p-2">
                <div className="d-flex justify-content-center mb-4"><img style={{height:"80px", width:"80px"}} src="https://images-tnb.s3.ap-south-1.amazonaws.com/main/vectors/100%25natural%402x.png" alt=""/></div>
                <div className="px-3"><h3 className="text-center">100% NATURAL</h3></div>
            </div>
            <div className="m-2 p-2">
                <div className="d-flex justify-content-center mb-4"><img style={{height:"80px", width:"80px"}} src="https://images-tnb.s3.ap-south-1.amazonaws.com/main/vectors/sustainable.png" alt=""/></div>
                <div className="px-3"><h3 className="text-center">SUSTAINABLY FARMED</h3></div>
            </div>
            <div className="m-2 p-2">
                <div className="d-flex justify-content-center mb-4"><img style={{height:"80px", width:"80px"}} src="https://images-tnb.s3.ap-south-1.amazonaws.com/main/vectors/labtested.png" alt=""/></div>
                <div className="px-3"><h3 className="text-center">LAB TESTED</h3></div>
            </div>
        </div>
    )
}
