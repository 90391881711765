import React, {useState} from 'react'
import { useMediaQuery } from 'react-responsive';
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader'
import AddressForm from './AddressForm';
import AddressList from './AddressList';


function Addresses (props){
    const isPhone = useMediaQuery({ query : '(max-device-width:1024px)'});
    if(isPhone){
        if(document.getElementById("landing")){
            document.getElementById("landing").style.display = "block"
        }
        if(document.getElementById("side")){
        document.getElementById("side").style.display = "none"
        }
    }
    
    const [modalVisibility, setModalVisibility] = useState(false);

    const openModal = () => {
        setModalVisibility(true)
    }
    const closeModal = () => {
        setModalVisibility(false)
    }


        return (
            <div>
                <h2 className="m-3">Saved Addresses</h2>
                <AddressList />
                <div className="d-flex justify-content-center" style={{marginTop:"25vh"}}>
                    <button onClick={openModal} className="border border-success bg-transparent text-success px-3 py-2 rounded">
                        Add new address
                    </button>
                </div>
                

                <Modal
                    show={modalVisibility}
                    onHide={closeModal} 
                    centered backdrop="static" 
                    keyboard={false}
                    size="lg">
                    <ModalHeader className="bg-light" closeButton>
                        <ModalTitle>Add new Address</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <AddressForm close={closeModal} />
                    </ModalBody>
                </Modal>
            </div>
        )
}


export default Addresses
