import React from 'react'
import logo from '../Logo/white-logo.png'
import phoneicon from '../Icons/phone.svg'
import emailicon from '../Icons/email.svg'

export default function Footer() {
    return (
        <div className="footerLevel2Div" id="footer">
            <div className="d-flex flex-wrap-reverse pt-4 px-4 justify-content-between">
        
                <div className="d-flex mt-4 mt-sm-0 justify-content-between flex-column text-white">
                    <div>
                        <img src={logo} alt="TNB" style={{width:"120px", marginBottom:"20px"}}/>
                        <div>
                            <img src={phoneicon} alt="Ph" className="mr-2"/><a href="tel:+917767885222" className="text-white"><u>+91 7767885222</u></a>
                        </div>
                        <div className="mb-4"><img src={emailicon} alt="E" className="mr-2"/><a href="mailto:hello@thenaturesbowl.com" className="text-white"><u>hello@thenaturesbowl.com</u></a></div>
                    </div>
                    <p className="text-muted">Copyright &copy; 2020. All rights reserved.</p>
                </div>

                <div className="mt-3 mt-sm-0 mb-5">
                    <p className="font-weight-bold text-success">Quick Links</p>
                    <a href="/aboutus"><p className="text-white">About us</p></a>
                    {/* <p>Blogs</p> */}
                    <a href="/home/privacy_policy"><p className="text-white">Privacy Policy</p></a>
                    <a href="/home/terms"><p className="text-white">Terms & conditions</p></a>
                </div>

                <div className="mt-3 mt-sm-0 mb-5">

                    <p className="font-weight-bold text-success">Follow Us</p>
                    <a target='_blank' href="https://www.facebook.com/thenaturesbowl"><p className="text-white">Facebook</p></a>
                    <a target='_blank' href="https://www.instagram.com/thenaturesbowl/"><p className="text-white">Instagram</p></a>
                    <a target='_blank' href="https://www.linkedin.com/company/the-nature-s-bowl/"><p className="text-white">LinkedIn</p></a>
                </div>
            </div>
        </div>
    )
}
