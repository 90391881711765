export const setLine1 = (line1) => {
    return (dispatch) => {
        dispatch({
            type : "SET_LINE1",
            line1
        })
    }
}

export const setLine2 = (line2) => {
    return (dispatch) => {
        dispatch({
            type : "SET_LINE2",
            line2
        })
    }
}


export const setPincode = (pincode) => {
    return (dispatch) => {
        dispatch({
            type : "SET_PINCODE",
            pincode
        })
    }
}

export const setCity = (city) => {
    return (dispatch) => {
        dispatch({
            type : "SET_CITY",
            city
        })
    }
}


export const setState = (state) => {
    return (dispatch) => {
        dispatch({
            type : "SET_STATE",
            state
        })
    }
}

export const setFullAddr = (addr) => {
    return (dispatch) => {
        dispatch({
            type: "SET_FULL_ADDR",
            addr
        })
    }
}