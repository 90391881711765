import React, { Component } from 'react';
import { loginUser } from '../../../../store/actions/loginUser';
import { connect } from 'react-redux';
import googleIcon from '../../../../Icons/google.svg'
import fbIcon from '../../../../Icons/fb.svg'
import { InputGroup, FormControl } from 'react-bootstrap';
import url from '../../../../url.json'
import { Redirect } from 'react-router-dom';

export class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            otp : '',
            validNumber : true,
            OTPReceived : false,
            OTPError : false,
            OTPMsg : "",
            OTPErrMsg : "",
            OTPVerifError : false,
            otpSessionID : "",
            loading : false

        };
    }

    phoneNoFormat = /^\d{10}$/;

    handlephoneChange = (e) => {
        this.setState({ phone : e.target.value})
        if(e.target.value.match(this.phoneNoFormat)){
            this.setState({validNumber : true})
        }
    }

    handlePasswordChange = (e) => {
        this.setState({ password : e.target.value})
    }

    handleOTPChange = (e) => {
        this.setState({otp : e.target.value})
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        // console.log("EEE", process.env.API)
        if (this.state.phone.match(this.phoneNoFormat)){
            this.setState({ validNumber : true })
            this.setState({ loading : true })
            //make a post call to db to send the phone number entered and then send an otp to the number
            fetch(`${url.url}/api/auth/otp`, {
                method : 'POST',
                headers: {
                    'Content-Type': 'application/json'
                  },
                body : JSON.stringify({
                    phone : this.state.phone
                })

            })
            .then(res => res.json())
            .then((resJson) => {
                console.log("Response from the backend", resJson.message);
                this.setState({
                    OTPReceived : resJson.otpSent,
                    OTPError : resJson.error,
                    otpSessionID : resJson.otpSessionID,
                    loading : false
                })  
            })
        }
        else {
            this.setState({validNumber : false, phone : ""})
        }

    }

    resetLogin = () =>{
        this.setState({
            phone: '',
            otp : '',
            validNumber : true,
            OTPReceived : false,
            OTPError : false,
            OTPMsg : "",
            OTPErrMsg : "",
            OTPVerifError : false,
            otpSessionID : "",
            loading : false
        })
    }
    handleOTPSubmit = (e) => {
        e.preventDefault();
        this.setState({loading : true})
        fetch(`${url.url}/api/auth/otp/verify`, {
            method : 'POST',
            headers: {
                'Content-Type': 'application/json'
              },
            body : JSON.stringify({
                otp : this.state.otp,
                otpSessionID : this.state.otpSessionID,
                phone : this.state.phone
            })

        })
        .then(res => res.json())
        .then((resJson) => {
            console.log("Verification process complete", resJson);
            if (resJson.status === "Success"){
                console.log("Verification status success", resJson);
                this.props.loginUser(resJson.user)
                if(this.props.gotoCheckout){
                    window.open('/checkout');
                    window.location.reload();
                }
                // this.setState({loading : false, OTPVerifError : false, OTPError : false})
            }
            if(resJson.status === "Failure"){
                console.log("Verification status failure", resJson);
                this.setState({loading : false, OTPVerifError : true, OTPErrMsg : "OTP did not match"})
            }
            if(resJson.status === "Expired"){
                console.log("Verification status failure", resJson);
                this.setState({loading : false, OTPVerifError : true , OTPErrMsg : "OTP Expired"})
            }
        })
    }
    


    facebookLogin = async () => {
        window.open(`${url.url}/api/auth/facebook`, "_self");
        //make an api call after a succesful loginto fetch the profile of the logged on user from the backend session
    }

    googleLogin = async () => {
        window.open(`${url.url}/api/auth/google`, "_self");
    }

    render() {

        let mobileNumberForm = 
        <div className="m-auto w-75">
            <form onSubmit={this.handleFormSubmit}>
                <InputGroup className="border border-light py-1 rounded mb-2" style={{ backgroundColor: "#F5F5F5"}}>
                    <InputGroup.Prepend><InputGroup.Text className="bg-transparent border-0">+91</InputGroup.Text></InputGroup.Prepend>
                    <FormControl type='text' value={this.state.phone}  onChange={this.handlephoneChange}  placeholder="Mobile number" className="bg-transparent border-bottom-0 border-right-0 border-top-0 border-left border-secondary py-3"/>{/* <input type='text' value={this.state.phone}  onChange={this.handlephoneChange}  placeholder="Mobile number"/> */}
                </InputGroup>
                { this.state.validNumber ? <></> : <p>Invalid number. Only digits (0-9) allowed.</p>}
                <input type='submit' value='Continue' className="w-100 mt-3 p-2 rounded shadow-sm border-0" style={{backgroundColor:"#90DA7B"}}/>
            </form>
            {this.state.OTPError 
                ? 
                    <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        Please try again later
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                :
                    null
            }
         </div>

        let OTPForm = 
        <div className="m-auto w-75">
            {this.state.OTPVerifError 
                ? 
                    <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        {this.state.OTPErrMsg}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                :
                    null
            }
            <form onSubmit={this.handleOTPSubmit}>
                <FormControl type='text' value={this.state.otp}  onChange={this.handleOTPChange} className="bg-light mb-3 mt-3" placeholder="Enter OTP"/>
                <input type='submit' value='Verify' className="w-100 mt-3 p-2 mt-3 rounded shadow-sm border-0" style={{backgroundColor:"#90DA7B"}}/>
                <div className="d-flex justify-content-between">
                    <u role="button" className="text-info" onClick={this.handleFormSubmit}>Resend OTP</u>
                    <u role="button" className="text-info" onClick={this.resetLogin}>Change number</u>
                </div>
            </form>
        </div>

        return (
            <div className="mt-5">
                <h4 className="d-flex justify-content-center mb-4">Login to your account with</h4>
                <div className="d-flex justify-content-center mt-3 mb-4">
                    <img className="mr-3 border border-dark px-3 py-2" role="button" src={fbIcon} onClick={this.facebookLogin} alt="Fb"/>
                    <img className="ml-3 border border-dark px-3 py-2" role="button" onClick={this.googleLogin} src={googleIcon} alt="G"/>
                </div>
                <h6 className="d-flex justify-content-center my-3">OR</h6>
                <h4 className="d-flex justify-content-center my-3">Login using mobile number</h4>
                { this.state.OTPReceived ? OTPForm : mobileNumberForm }
                <div className="d-flex justify-content-center justify-content-between justify-content-around">
                    <p>{this.state.OTPMsg}</p>
                    {/* {this.state.OTPReceived ? <u role="button" className="text-primary st" onClick={this.handleFormSubmit}>Resend</u> : null} */}
                </div>
                {this.state.loading ? 
                    <div className="d-flex justify-content-center my-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> : null }
            </div>
            // <div className="d-flex flex-column justify-content-around mt-5">
            //     <h6>Login to your account with</h6>
            //     <div className="d-flex justify-content-center mt-2 mb-3">
            //         <img className="mr-3 border border-dark px-3 py-2" role="button" onClick={this.facebookLogin} src={fbIcon}/>
            //         <img className="ml-3 border border-dark px-3 py-2" role="button" onClick={this.googleLogin} src={googleIcon}/>
            //     </div>
            //     <p className="mb-3">or</p>
            //     <p className="mb-3">Login using mobile number</p>
            //     { this.state.OTPReceived ? OTPForm : mobileNumberForm }
            //     <div className="d-flex justify-content-center justify-content-between justify-content-around">
            //         <p>{this.state.OTPMsg}</p>
            //         {/* {this.state.OTPReceived ? <u role="button" className="text-primary st" onClick={this.handleFormSubmit}>Resend</u> : null} */}
            //     </div>
            //     {this.state.loading ? 
            //         <div className="d-flex justify-content-center">
            //             <div className="spinner-border" role="status">
            //                 <span className="sr-only">Loading...</span>
            //             </div>
            //         </div> : null }
            // </div>
        )
        // return (
        //     <div>
                // { this.state.OTPReceived ? OTPForm : mobileNumberForm }
                // <div className="d-flex justify-content-center justify-content-between justify-content-around">
                //     <p>{this.state.OTPMsg}</p>
                //     {/* {this.state.OTPReceived ? <u role="button" className="text-primary st" onClick={this.handleFormSubmit}>Resend</u> : null} */}
                // </div>
                // {this.state.loading ? 
                //     <div className="d-flex justify-content-center">
                //         <div className="spinner-border" role="status">
                //             <span className="sr-only">Loading...</span>
                //         </div>
                //     </div> : null }
        //         <div className="btn btn-primary" onClick={this.facebookLogin}>Login with FB</div>
        //         <div className="btn btn-primary" onClick={this.googleLogin}>Login with google</div>
        //     </div>
        // )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser : (user) => dispatch(loginUser(user))
    }
}

export default connect(null,mapDispatchToProps)(Login)
