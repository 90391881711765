import React, { Component } from 'react'
import ProductCard from './ProductCard'
import './ProductCard.css'
export class Products extends Component {
    
    render() {
        const products = this.props.items.map((product,index) =>
            <ProductCard product= {{...product, id : product.PK}} key={index} />
            )

        return (
            <div className="productContainer p-md-3" >
                {products}
            </div>
        )
    }
}

export default Products
