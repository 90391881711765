import store from '../configureStore'
import url from '../../url.json'

export const deleteAddress = (id,idx) => {
    return (dispatch) => {
        fetch(`${url.url}/api/user/address`,{
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            body : JSON.stringify({
                userPK : store.getState().profile.PK,
                idx : idx,
                id : id,
                addresses : store.getState().profile.addresses

            })
        })
        .then(res => {
            if (res.status === 200) return res.json()
            console.log("Failed to delete address");
            throw new Error("failed to delete address");
        })
        .then(resJson => {
            if (resJson.error){
                return;
            }
        })
        .catch(error => {
            console.log("Error deleting address", error);
        })
        
        dispatch({
            type: "DELETE_ADDRESS",
            id
        })
    }
}