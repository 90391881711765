const initState = {}

const currOrderReducer = (currOrder=initState, action) => {
    switch(action.type){
        case "CREATE_ORDER":
            return {
                ...currOrder,
                ...action.order
            }
        case "ADD_PAYMENT_DETAILS":
            // console.log("hererererere?rerererer")
            return {
                ...currOrder,
                razorpay_order_id : action.payload.razorpay_order_id,
                razorpay_payment_id : action.payload.razorpay_payment_id,
                razorpay_signature : action.payload.razorpay_signature
            }
        case "RESET":
            return {}
        case "ADD_RAZORPAY_ID":
            return {
                ...currOrder,
                razorpay_init_order_id : action.razorpay_init_order_id
            }
        case "ADD_COUPON":
            return {
                ...currOrder,
                coupon : action.coupon
            }
        default:
            return currOrder
    }
}

export default currOrderReducer