import React, { Component, useEffect }  from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import './App.css';
import Main from './Components/Main/Main';
import Checkout from './Components/Checkout/Checkout'
import PageNotFound from './Components/PageNotFound';
import OrderConfirmation from './Components/OrderConfirmation';
import Auth from './Components/Auth';
import { loadProductData } from './store/actions/loadProductData';
import { connect } from 'react-redux';
import url from './url.json'
import AboutUs from './Components/AboutUs/AboutUs';
import Thankyou from './Components/Thankyou/Thankyou';
import ScrollToTop from './Components/ScrollToTop';
import { updateWishlist } from './apis/updateWishlistAPI';
import { updateCartInfo } from './apis/updateCartInfo';


function App (props) {

  useEffect(() => {
    props.loadProducts()
  }, []);

  useEffect(() => {
    if(props.user.wishlist){
      updateWishlist(props.user.PK, props.user.wishlist)
    }
    return;
  }, [props.wishlist]);

  useEffect(() => {
    if(props.authenticated){
      updateCartInfo(props.cart, props.user);
    }
    return;
  }, [props.cart]);
  
  return (
      <div className="App" id="App">
        <ScrollToTop />
        <Switch>
          <Route exact path='/'>
            <Redirect to='/home'/>
          </Route>
          <Route path ='/home' component={Main}/>
          <Route path ='/auth' component={Auth}/>
          <Route exact path='/checkout/:payment_failure?' component = {Checkout} />
          <Route exact path='/aboutus' component = {AboutUs} />
          <Route exact path='/orderConfirmation' component= {OrderConfirmation} />
          <Route exact path='/thankyou/:paymentMode' component = {Thankyou} />
          <Route component={PageNotFound} />
        </Switch>

      </div>   
  );
  
}
const mapStateToProps = (state) => {
  return {
    user : state.profile,
    orders : state.orders,
    authenticated : JSON.stringify(state.profile) === "{}" ? false : true,
    cart: state.cart
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadProducts : () => dispatch(loadProductData())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App)