import React, { useState, useEffect } from 'react'
import CardforCart from './CardforCart'
import {connect} from 'react-redux'
import { NavLink } from 'react-router-dom'
import './Cart.css'
import { Modal } from 'react-bootstrap'
import { Login } from '../Profile/Login/Login'
import { loginUser } from '../../../store/actions/loginUser'

function Cart (props) {

    const [subTotal, setSubTotal] = useState(0)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCheckoutPress = () => {
        //for facebook pixel
        window.fbq('track', 'InitiateCheckout');
        if(props.authenticated){
            window.open("/checkout", "_blank")
        }else{
            handleShow();
        }
    }

    useEffect(()=> {
        setSubTotal(props.items.reduce((acc, item)=>{
            return acc + (item.qty*item.discountedPrice)
        }, 0))
    }, [props.items])


    const items = props.items && props.items.map((item,index)=>(
        <CardforCart key={index} item={item}/>
    ))


    return (
        <div>
            <Modal centered show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Login to enable offers</Modal.Title>
                </Modal.Header>
                <Login loginUser={props.loginUser} gotoCheckout={true}/>
                <div className="mb-4">
                    <h6 className="d-flex justify-content-center my-3">Or</h6>
                    <div className="d-flex justify-content-center">
                        <NavLink to="/checkout" target="_blank" className="w-75 h-50"><button className="w-100 border-0 rounded p-2" style={{background:"#90DA7B"}} onClick={{handleClose}}>Continue as Guest</button></NavLink>
                    </div>
                </div>
            </Modal>
            <div className="d-flex justify-content-around px-1 py-2">
                <h2>Your Cart</h2>
                {props.noOfItems === 1 ? <h2>{props.noOfItems} Item</h2> : null}
                {props.noOfItems > 1 ? <h2>{props.noOfItems} Items</h2> : null}
            </div>
            <div className="mt-1 cartItems">
                {props.items.length < 1 ? <h5 className="h-100 mt-5 pt-5 text-success font-weight-light">Your cart is currently empty. Please add some goodness!</h5>: items}
            </div>
            <div className="checkoutBtn">
                <div className="h-50">
                    { props.items.length < 1 ? null : <h4 className="text-right font-weight-bold py-1">Subtotal &#8377;{subTotal}</h4> }
                </div>
                {
                    props.items.length < 1 
                        ?   <button className="btn w-100 h-50" disabled>
                                <h5 className="text-dark font-weight-light">Checkout</h5>
                            </button>
                        :   
                            // <NavLink to="/checkout" target="_blank" role="button" className="btn w-100 h-50">
                            <button className="w-100 rounded border-0" style={{background:"#75CB6F", height:"55%"}} onClick={handleCheckoutPress}>Checkout</button>
                            // </NavLink>
                }
            </div>
        </div>
    )
    
}
const mapStateToProps = (state) => {
    return {
        items : state.cart,
        noOfItems : state.cart.reduce((acc,item)=>{
            return acc + item.qty
        },0),
        // authenticated : state.user,
        authenticated : JSON.stringify(state.profile) === "{}" ? false : true
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser : (user) => dispatch(loginUser(user))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Cart)
