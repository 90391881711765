import store from '../configureStore';
import url from '../../url.json';

export const incCouponUse = (coupon) => {
    return (dispatch) => {
        //make a netw call here, and then after recieving the response perform the dispatch

        //if the coupon isn't used by the user yet, make an entry in user.coupons with timesUsed=1
        //else increment the number of times it has been used


        const userCoupons = store.getState().profile.coupons
        const updatedCoupons = userCoupons ?
            userCoupons.map(cpn => cpn.code).includes(coupon) ?
                userCoupons.map(cpn=> cpn.code === coupon ? ({...cpn, timesUsed: cpn.timesUsed+1}) : cpn) //return val
                :
                userCoupons.concat({'code': coupon, 'timesUsed':1}) //return val
            : 
            [{'code': coupon, 'timesUsed':1}] //return val
        
        
        fetch(`${url.url}/api/user/coupon`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body : JSON.stringify({
                'user' : store.getState().profile,
                'updatedCoupons' : updatedCoupons
            })
        })
        .then(res => {
            if (res.status === 200) return res.json()
            throw new Error("Failed to update coupon");
        })
        .then(resJson => {
            dispatch({
                type : "INC_COUPON_USE",
                updatedCoupons
            })
        })
        .catch(error => {
            console.log(error)
        })        
    }
}