

import url from '../../url.json';
export const continueWithSodexo = (order,sodexoSourceId) => {
    fetch(`${url.url}/api/payment/sodexo/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body : JSON.stringify({...order, sodexoSourceId:sodexoSourceId})
    })
    .then(res => {
        if (res.status === 200) return res.json()
        console.log("Failed to create order");
        throw new Error("failed to create order");
    })
    .then(resJson => {
        window.location.replace(resJson.redirecturl)
    })
    .catch(error => {
        window.alert("Service not available")
        console.log("Error creating sodexo order", error);
    })
}