import React, {useEffect} from 'react'
import PlainHeader from '../Header/PlainHeader'
import './thankyou.css'
import image from '../../Images/rafiki.svg'
import { NavLink } from 'react-router-dom'
import { emptyCart } from '../../store/actions/cart/emptyCart'
import { resetCurrOrder } from '../../store/actions/resetCurrOrder'
import { connect } from 'react-redux'
import url from '../../url.json'
import crypto from 'crypto'
import { updateQuantityInDB } from '../../apis/updateQuantityinDB'
import { incCouponUse } from '../../store/actions/incCouponUse'
import { resetCheckout } from '../../store/actions/checkout/resetCheckout'
import rpConfig from '../../config/razorpay.json'

function Thankyou(props) {

    useEffect(()=>{
        
        let generatedSign = crypto.createHmac('sha256', rpConfig.rp_secret)
            .update(props.currOrder.razorpay_init_order_id + "|" + props.currOrder.razorpay_payment_id)
            .digest('hex')

        // console.log("Signature verificaiton data",generatedSign === props.currOrder.razorpay_signature, generatedSign, props.currOrder.razorpay_signature)
        if (props.currOrder.razorpay_signature && generatedSign === props.currOrder.razorpay_signature || props.match.params.paymentMode === "postpaid"){
            fetch(`${url.url}/api/orders/status`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify({
                    order : props.currOrder,
                    status : "confirmed"
                })
            })
            .then(res => {
                if (res.status === 200) return res.json()
                console.log("Failed to change status in db");
            })
            .then(resJson => {
                if (resJson.error){
                    console.log("Error changing order status in db"); 
                }else{
                    console.log("Verification complete")
                    //for facebook pixel
                    window.fbq('track', 'Purchase', {value: props.currOrder.txnAmount, currency: 'INR'});
                    props.currOrder.coupon && props.currOrder.coupon !== '' && props.incCouponUse(props.currOrder.coupon)
                    props.resetCurrOrder()
                    updateQuantityInDB(props.cart)
                    props.emptyCart()
                    props.resetCheckout()
                }
            })
            .catch(error => {
                console.log("Error changing order status in db", error); 
            })
            
        
        }
    },[])

    useEffect(()=>{
        if(JSON.stringify(props.currOrder) === "{}"){
            props.emptyCart()
        }
    }, [props.currOrder])

    return (
        <div className="thankyou">
            <div className="bg-white" style={{boxShadow:"0px 2px 8px rgba(0, 0, 0, 0.1)"}}>
                <PlainHeader />
            </div>
            <div className="bg-white tile px-3 py-5">
                <div className="d-flex justify-content-around"><img src={image} className="imgDiv"/></div>
                <h4 className="text-center mt-5">Your order is complete</h4>
                <p className="text-center mt-2">You will recieve a confirmation email with the order details.</p>
                <NavLink className="d-flex justify-content-around" to="/home"><button style={{background:"#90DA7B", border:"none"}} className="rounded px-3 py-1">Continue Shopping</button></NavLink>
            </div>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        incCouponUse : (coupon) => dispatch(incCouponUse(coupon)),
        emptyCart : ()=> dispatch(emptyCart()),
        resetCurrOrder : ()=> dispatch(resetCurrOrder()),
        resetCheckout : ()=>dispatch(resetCheckout())
    }
}

const mapStateToProps = (state) => {
    return {
        cart : state.cart,
        currOrder : state.currOrder
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Thankyou)
