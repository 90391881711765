import React, { Component } from 'react'
import Landing from '../Landing/Landing'
import Side from '../Side/Side'
import Header from '../Header/Header'
import NavbarFunc from '../Navbar/NavbarFunc'

export class Main extends Component {
    render() {
        
        const path = this.props.match.path
        return (
            <div>
                <div style={{boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)"}}>
                    <Header />
                    <NavbarFunc path={path}/>
                </div>
                <div className="main">
                    {/* <div> */}
                        <Landing path={path} />
                    {/* </div> */}
                    {/* <div> */}
                        <Side />
                    {/* </div> */}
                    
                </div>
            </div>
        )
    }
}

export default Main
