import React from 'react'
import img2 from '../../Images/salesPage_2.jpg'
import img4 from '../../Images/sp_last.jpg'
import './aboutus.css'
import Features from '../Landing/ProductPage/Features'
import Footer from '../Footer'
import PlainHeader from '../Header/PlainHeader'
import logo from '../../Logo/white-logo.png'

export default function AboutUs() {
    return (
        <div>
            <div className="spLogo">
                <PlainHeader logo={logo}/>
            </div>
            <div className="topBanner">
                <h1 className="mb-5">The Honest Food Revolution</h1>
                <h5 className="mb-4 ">The Nature’s Bowl took its roots when two motivated, highly driven childhood friends decided to bring a positive revolution in the food retail market. We were surprised at how everyone was overlooking problems of the modern world –</h5>
                <h5 className="mb-4 ">Young adults are contracting terminal diseases and experiencing extreme levels of fatigueness and depression more than ever. This was in strong contrast to our forefathers who were fit and lively even in their late lean years.</h5>
                <h5>Who was at fault?</h5>
            </div>
            <div className="row py-5 px-2 m-0">
                <div className="col-12 col-md-6 p-0 m-0">
                    <img src={img2} className="img2"/>
                </div>
                <div className="col-12 col-md-6 p-0 m-0">
                    <h2 className="spHeading">Calling out double standards</h2>
                    <p className="text ">The food available at the commercial market is highly processed and polished which results in side effects because of the adulteration and deficiency it contains.<br/><br/>
                        We realised there was a major discrepancy in the retail market.<br /><br />
                        A major shift had to take place…<br />
                        The commercial hypocrisy had to  stop.
                    </p>
                </div>
            </div>
            <div className="flex flex-md-row-reverse row py-5 px-2 m-0">
                <div className="col-12 col-md-6 p-0 m-0">
                    <img src="https://images-tnb.s3.ap-south-1.amazonaws.com/main/banners/Sales+page+image.jpg" className="img2"/>
                </div>
                <div className="col-12 col-md-6 p-0 m-0">
                    <h2 className="spHeading mt-1 mb-5">Making the nature’s Bowl Happen!</h2>
                    <p className="text ">
                        We joined hands to bring the organic industry to its full bloom and initiated <b>The Nature’s Bowl</b><br /><br />
                        Here, at The Nature’s Bowl,<br/><br/>
                        <ul>
                            <li>We provide the purest, top-quality, 100% organic food.</li>
                            <li>The food is naturally enriched with medicinal properties to keep your family safe and healthy.</li>
                            <li className="d-none">Make certain we leave behind minimal carbon footprint on our planet.</li>
                            <li>Doorstep delivery</li>
                        </ul>
                        <br/>
                        Keeping in mind India’s sentiment and bond shared with food, The Nature’s Bowl is on a mission to make sure everybody eats healthy and unadulterated food.<br/><br/>
                        Become a part of the #TheHonestFood revolution by loving and eating from The Nature’s Bowl.<br/><br/>
                        Cheers!
                    </p>
                </div>
            </div>
            <div className="my-5">
                <h2 className="text-center my-5 font-weight-bold spHeading">Features</h2>
                <Features />
            </div>
            <div className="w-100 position-relative">
                <img className="w-100" src={img4}/>
                <div className="w-100 d-flex justify-content-around" style={{position:'absolute', bottom:"20px"}}>
                    <a href="/home">
                        <button className="rounded border-0" style={{background:"#75CB6F"}}>Start Shopping</button>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    )
}
