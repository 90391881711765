import React from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import thumbnail from '../../../../Images/thumbnail.jpeg';

export default function Item(props) {

    let history = useHistory();

    const handleClick = () => {
        // history.push(`/category/${props.name}`)
        // return <Redirect to={`/category${props.name}`} />
    }

    return (
        <NavLink to={`/home/category/${props.name}`} className="carousal-cat-item" >
            <img src={`https://images-tnb.s3.ap-south-1.amazonaws.com/main/Category+thumbnails/${props.name}.jpg`} alt="Thumbnail"/>
            <h3 className="text-dark">{props.name}</h3>
        </NavLink>
    )
}
