import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import OrderItem from './OrderItem';

function Orders (props) {
    const isPhone = useMediaQuery({ query : '(max-device-width:1024px)'});
    if(isPhone){
        if(document.getElementById("landing")){
            document.getElementById("landing").style.display = "block"
        }
        if(document.getElementById("side")){
        document.getElementById("side").style.display = "none"
        }
    }
    console.log("order from here finally", props.orders);
    return (
        <div className="p-1 p-md-4">
            <h2>Your Orders</h2>
            {props.orders.length < 1 ? <h3 className="text-center"><i>No orders to show</i></h3>:null}
            {props.orders.map(item=>{
                return <OrderItem order={item}/>
            })}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        orders : state.orders.sort(function(a,b){
            return new Date(b.createdAt) - new Date(a.createdAt)
        })
    }
}
export default connect(mapStateToProps)(Orders)
