import url from '../../url.json'

export const logoutUser = () => {
    return async (dispatch) => {
        fetch(`${url.url}/api/auth/logout`, {credentials: 'include'})
        .then(res=>{
                if (res.status === 200) return res.json();
                console.log("Failed to authenticate");
                throw new Error("failed to authenticate user");
        })
        .then(resJson => {
            if (resJson.success){
                dispatch({type : "LOGOUT_USER"})
            }
        })
        .catch(e => {
            console.log("Error logging out", e)
        })
    }
}