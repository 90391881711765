import React from 'react'
import './catExplorer.css'
import Item from './Item'

export default function CatExplorer() {
    return (
        <div className="container mt-4 mb-3">
            <div className="row">
                <div id="carousel" className="carousel slide position-relative" data-interval="false">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="d-none d-lg-block">
                                <div className="slide-box">
                                    <Item name="Flours and Daliya" />
                                    <Item name="Cereal and Pulses"/>
                                    <Item name="Oils"/>
                                    <Item name="Spices"/>
                                </div>
                            </div>
                            <div className="d-none d-md-block d-lg-none">
                                <div className="slide-box">
                                    <Item name="Flours and Daliya" />
                                    <Item name="Cereal and Pulses"/>
                                    <Item name="Oils"/>
                                </div>
                            </div>
                            <div className="d-block d-md-none">
                                <div className="slide-box">
                                    <Item name="Flours and Daliya" />
                                    <Item name="Cereal and Pulses"/>
                                </div>
                            </div>                            
                        </div>
                        <div className="carousel-item">
                            <div className="d-none d-lg-block">
                                <div className="slide-box">
                                    <Item name="Sweeteners" />
                                    <Item name="Salts"/>
                                    <Item name="Tea"/>
                                    <Item name="Superfoods"/>
                                </div>
                            </div>
                            <div className="d-none d-md-block d-lg-none">
                                <div className="slide-box">
                                    <Item name="Spices" />
                                    <Item name="Sweeteners" />
                                    <Item name="Salts"/>
                                </div>
                            </div>
                            <div className="d-block d-md-none">
                                <div className="slide-box">
                                    <Item name="Oils"/>
                                    <Item name="Spices"/>
                                </div>
                            </div>                            
                        </div>
                        <div className="carousel-item">
                            <div className="d-none d-lg-block">
                                <div className="slide-box">
                                        <Item name="Flours and Daliya" />
                                        <Item name="Cereal and Pulses"/>
                                        <Item name="Oils"/>
                                        <Item name="Spices"/>
                                </div>
                            </div>
                            <div className="d-none d-md-block d-lg-none">
                                <div className="slide-box">
                                    <Item name="Tea" />
                                    <Item name="Superfoods"/>
                                </div>
                            </div>
                            <div className="d-block d-md-none">
                                <div className="slide-box">
                                    <Item name="Sweeteners"/>
                                    <Item name="Salts"/>
                                </div>
                            </div>                            
                        </div>
                        <div className="carousel-item">
                            <div className="d-none d-lg-block">
                                <div className="slide-box">
                                    <Item name="Sweeteners" />
                                    <Item name="Salts"/>
                                    <Item name="Tea"/>
                                    <Item name="Superfoods"/>
                                </div>
                            </div>
                            <div className="d-none d-md-block d-lg-none">
                                <div className="slide-box">
                                    <Item name="Flours and Daliya" />
                                    <Item name="Cereal and Pulses"/>
                                    <Item name="Oils"/>
                                </div>
                            </div>
                            <div className="d-block d-md-none">
                                <div className="slide-box">
                                    <Item name="Tea"/>
                                    <Item name="Superfoods"/>
                                </div>
                            </div>                            
                        </div>
 
                        
                    </div>

                    <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        {/* <span className="sr-only">Previous</span> */}
                    </a>
                    
                    <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        {/* <span className="sr-only">Next</span> */}
                    </a>
                </div>
            </div>
        </div>

    )
}
