import url from '../url.json'

export const updateQuantityInDB = (cart) => {

    Promise.all(cart.map((item,index)=>{
        // console.log("fetch update qty",typeof(parseInt(item.qty)))
        fetch(`${url.url}/api/catalog/update/quantity`, {
                    method : "PUT",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body : JSON.stringify({
                        PK : item.PK,
                        qtyToAdd : -parseInt(item.qty)
                    })
            })
        }))
    .then()
    .catch(e=>console.log("Error while updating quantity in DB",e));

}