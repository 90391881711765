import React ,{useState} from 'react'
import { Form, FormControl, FormText, InputGroup, Spinner } from 'react-bootstrap';
import {connect} from 'react-redux'
import { setNameShipping } from '../../store/actions/shippingInfo/setNameShipping';
import { setMobileShipping } from '../../store/actions/shippingInfo/setMobileShipping';
import { setLine1, setLine2, setPincode, setCity, setState } from '../../store/actions/shippingInfo/setAddress';
import url from '../../url.json'
import { setEmailShipping } from '../../store/actions/shippingInfo/setEmailShipping';

function FillAddress(props) {

    // const [servicable, setServicable] = useState(undefined);

    const [fullName, setFullName] = useState(props.shippingInfo.name);
    const [mobile, setMobile] = useState(props.shippingInfo.mobile);
    const [email, setEmail] = useState(props.shippingInfo.email);
    const [line1, setLine1] = useState(props.shippingInfo.line1);
    const [line2, setLine2] = useState(props.shippingInfo.line2);
    const [pincode, setPincode] = useState(props.shippingInfo.pincode)
    const [city, setCity] = useState(props.shippingInfo.city);
    const [state,setState] = useState(props.shippingInfo.state);
    const [loading, setLoading] = useState(false);

    const handleNameChange =(e) => {
        const val = e.target.value
        setFullName(e.target.value)
        props.setName(e.target.value)
        setTimeout(()=>{
            if(val.length > 0){
                document.getElementById("addFullName").style.borderColor = 'green'
            }else{
                document.getElementById("addFullName").style.borderColor = 'transparent'
            }
        },1000)
    }
    const handleMobileChange = (e) => {
        const val = e.target.value
        setMobile(e.target.value)
        props.setMobile(e.target.value)
        setTimeout(()=>{
            if(val.length === 10){
                document.getElementById("addMobile").style.borderColor = 'green'
            }else{
                document.getElementById("addMobile").style.borderColor = 'transparent'
            }
        },3000)
    }

    const handleEmailChange = (e) => {
        const val = e.target.value
        setEmail(e.target.value)
        props.setEmail(e.target.value)
        setTimeout(()=>{
            if(val.length > 0 && val.includes("@")){
                document.getElementById("addEmail").style.borderColor = 'green'
            }else{
                document.getElementById("addEmail").style.borderColor = 'transparent'
            }
        },3000)
    }
    const handlePinChange = (e) => {
        const val = e.target.value
        setPincode(e.target.value)
    
        if (val.length < 1){
            document.getElementById("addPincode").style.borderColor = 'transparent'
            props.setServicable(undefined)
            // setServicable(1)
        }
        else {
        setLoading(true);
        props.setServicable(undefined)
        

        //THE BELOW CODE IS THE ACTUAL CODE... PLEASE USE THAT ONCE THE ISSUE WITH FEDEX IS FIXED

        val.length === 6 &&
        fetch(`${url.url}/api/delivery/pincode`, {
                method : 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                    },
                body : JSON.stringify({
                    pincode : val
                })
            })
        .then(res => {
            if (res.status === 200) return res.json()
            throw new Error("Error validing pincode")
        })
        .then(resJson => {
            console.log("Response from fedex", resJson)
            if (resJson.pincodeFound){
                console.log(resJson)
                props.setPincode(val);
                document.getElementById("addPincode").style.borderColor = 'green';
                props.setServicable(1);
                setLoading(false)
                
            } else {
                props.setServicable(0)
                props.setPincode(val);
                setLoading(false)
            }
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
        })
        }
        // else{
        //     document.getElementById("addPincode").style.borderColor = 'transparent'
        //     setServicable(1)
        // }
    }
    const handleLine1Change = (e) => {
        const val = e.target.value
        setLine1(e.target.value)
        props.setLine1(e.target.value)
        setTimeout(()=>{
            if(val.length > 0){
                document.getElementById("addLine1").style.borderColor = 'green'
            }else{
                document.getElementById("addLine1").style.borderColor = 'transparent'
            }
        },1000)
    }
    const handleLine2Change = (e) => {
        const val = e.target.value
        setLine2(e.target.value)
        props.setLine2(e.target.value)
        setTimeout(()=>{
            if(val.length > 0){
                document.getElementById("addLine2").style.borderColor = 'green'
            }else{
                document.getElementById("addLine2").style.borderColor = 'transparent'
            }
        },1000)
    }

    const handleCityChange = (e) => {
        const val = e.target.value
        setCity(e.target.value)
        props.setCity(e.target.value)
        setTimeout(()=>{
            if(val.length > 0){
                document.getElementById("addCity").style.borderColor = 'green'
            }else{
                document.getElementById("addCity").style.borderColor = 'transparent'
            }
        },1000)
    }

    const handleStateChange = (e) => {
        const val = e.target.value
        setState(e.target.value)
        props.setState(e.target.value)
        setTimeout(()=>{
            if(val.length > 0){
                document.getElementById("addState").style.borderColor = 'green'
            }else{
                document.getElementById("addState").style.borderColor = 'transparent'
            }
        },1000)
    }

    

    // useEffect(()=> {

    //         fetch("http://localhost:4000/api/delivery/pincode", {
    //             method : 'POST',
    //             headers: {
    //                 "Accept": "application/json",
    //                 "Content-Type": "application/json"
    //                 },
    //             body : JSON.stringify({
    //                 pincode : pincode
    //             })
    //         })
    //         .then(res => {
    //             if (res.status === 200) return res.json()
    //             throw new Error("Error validing pincode")
    //         })
    //         .then(resJson => {
    //             if (resJson.pincodeFound){
    //                 console.log(resJson,resJson.pre_paid, typeof (resJson.pre_paid))
    //                 resJson.pre_paid === "Y" ? setServicable(1) : setServicable(0)
    //                 setState(resJson.state_code);
    //                 setCity(resJson.district);
    //                 props.setPincode(pincode);
    //                 props.setCity(resJson.district);
    //                 props.setState(resJson.state_code);
    //             } else {
    //                 setServicable(0)
    //                 setCity("");
    //                 setState("");
    //                 props.setPincode(pincode);
    //                 props.setCity("");
    //                 props.setState("");
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err)
    //         })

    // }, [pincode])


    return (
        <div>
            {props.error ? <p className="text-info">Please rectify the highlighted fields</p> : null}
            <Form>
                <FormControl className="addrFormControl" id="addFullName" type="text" value={fullName} onChange={handleNameChange} placeholder="Full name"></FormControl>
                <InputGroup className="addrFormControl">
                    <InputGroup.Prepend><InputGroup.Text>+91</InputGroup.Text></InputGroup.Prepend>
                    <FormControl id="addMobile" type="text" value={mobile} onChange={handleMobileChange} placeholder="Mobile Number"></FormControl>
                </InputGroup>
                <FormControl className="addrFormControl" id="addEmail" type="text" value={email} onChange={handleEmailChange} placeholder="Email"></FormControl>
                <FormControl className="addrFormControl" id="addLine1" type="text" value={line1} onChange={handleLine1Change} placeholder="Address line 1"></FormControl>
                <FormControl className="addrFormControl" id="addLine2" type="text" value={line2} onChange={handleLine2Change} placeholder="Address line 2"></FormControl>
                <FormControl className="addrFormControl" id="addPincode" type="text" value={pincode} onChange={handlePinChange} placeholder="Pincode"></FormControl>
                {loading == false || pincode.length !== 6 
                                ? 
                                    <></> 
                                : 
                                    <FormText className="d-flex justify-content-around text-info"> 
                                        Checking servicability ...
                                    </FormText>
                }
                {props.servicable === 1 
                                ? 
                                    <FormText className="d-flex justify-content-around mr-md-5 text-success"> 
                                        Standard (3-5 days) Delivery available
                                     </FormText> 
                                : 
                                    null
                }

                {props.servicable === 0 && pincode.length === 6
                    ?
                        <FormText className="d-flex justify-content-around text-danger"> 
                            We are currently not delivering in this area<br />
                            Please try again or enter a different pincode
                        </FormText> 
                    :
                        null
                    }
                <FormControl className="addrFormControl" id="addCity" type="text" value={city} placeholder="City" onChange={handleCityChange}></FormControl>
                <FormControl className="addrFormControl" id="addState" type="text" value={state} placeholder="State" onChange={handleStateChange}></FormControl>
            </Form>           
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        shippingInfo : state.shippingInfo,
        error : state.checkout.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setName : (name) => dispatch(setNameShipping(name)),
        setMobile : (mobile) => dispatch(setMobileShipping(mobile)),
        setEmail : (email) => dispatch(setEmailShipping(email)),
        setLine1 : (line1) => dispatch(setLine1(line1)),
        setLine2 : (line2) => dispatch(setLine2(line2)),
        setPincode : (pincode) => dispatch(setPincode(pincode)),
        setCity : (city) => dispatch(setCity(city)),
        setState : (state) => dispatch(setState(state))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FillAddress)
