import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './Header.css'
import { NavLink } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

function Search(props) {

    const [input, setInput] = useState("");
    const [sugg, setSugg] = useState([]);

    const handleChange = (e) => {
        document.getElementById("searchResult-id").style.display = "block"
        setInput(e.target.value)
    }

    useEffect(() => {
        if(input === ""){
            setSugg([])
        }else {
            setSugg(Object.keys(props.products).filter(key => key.includes(input)))
            
        }
    }, [input, props.products])

    const list = sugg.map(item => {
        const url = '/home/product/'+item
        return <li id="searchResult" role="button" className="bg-light p-2 border-top searchElement" key={item}><NavLink to={url} className="text-dark">{props.products[item]}</NavLink></li>;
    });
    
    // const list = sugg.map(item => {
    //     const url = '/home/product/'+item
    //     return <ListGroup.Item className="bg-light p-2 border-top searchElement" key={item}><NavLink to={url} className="text-dark">{props.products[item]}</NavLink></ListGroup.Item>;
    // });
    return (
        <div className="search mt-2 mt-md-2 position-relative">
            <input id="searchBar" type="text" placeholder="Search" value={input} aria-label="Search" onChange={handleChange} />
            <ul className="searchResult bg-light" id="searchResult-id">
                {list}
            </ul>
            {/* <ListGroup className="searchResult bg-light" id="searchResult-id">
                {list}
            </ListGroup> */}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        products : state.productData
    }
}

export default connect(mapStateToProps)(Search)
