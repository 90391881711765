import React, { Component } from 'react'
import Cart from '../Side/Cart/Cart'
import './Side.css'
import Profile from './Profile/Profile'
import Wishlist from './Wishlist/Wishlist'
import {connect} from 'react-redux'
import Login from './Profile/Login/Login'

export class Side extends Component {
    openLogin = () =>{
        document.getElementById("saved").style.display = 'none'
        document.getElementById("profile").style.display = 'block'
    }
    render() {
        const authenticated = JSON.stringify(this.props.user) === "{}" ? false : true
        // console.log("Authenticated val in side", authenticated);
        
        return (
            <div id="side">
                
                <div id="cart" className="cart">
                    <Cart />
                </div>

                <div id="profile" className="profile">
                    {authenticated ? <Profile /> : <Login/>}
                </div>
                <div id="saved" className="saved">
                    {authenticated 
                        ? 
                        <Wishlist /> 
                        :
                        <div className="wishlistMsg">
                            <h4 className="text-muted">Login to see your saved
                            items</h4>
                            <button className="addBtn w-25" onClick={this.openLogin}>Login</button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStatetoProps = (state) => {
    return {
        user : state.profile
    }  
}

export default connect(mapStatetoProps)(Side)
