import React, { Component } from 'react'
import heart from '../../../Icons/heart.svg'
import unsave from '../../../Icons/unsave.svg'
import './ProductPage.css'
import { connect } from 'react-redux';
import QuantityPicker from '../../QuantityPicker';
import shoppingCartBtn from '../../../Icons/shopping-cart-btn.svg'
import { addtoCart } from '../../../store/actions/cart/addtoCart';
import { addToWishlist } from '../../../store/actions/addToWishlist';
import { updateQty } from '../../../store/actions/cart/updateQty';
import { deleteFromWishlist } from '../../../store/actions/deleteFromWishlist';
import Features from './Features';
import FeaturedContainer from '../Products/FeaturedContainer'
import check from '../../../Icons/check.svg'
import url from '../../../url.json'
import { Spinner } from 'react-bootstrap';

export class ProductPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            item : {},
            featuredItems : [],
            loading : true
        }
    }
    discountPrice = undefined
    originalPrice = undefined

    handleAdd = () => {
        this.props.addToCart({...this.state.item, id:this.state.item.PK, qty:1})
    }

    like = () => {
        this.props.like(this.state.item, this.props.user.PK);
    }

    unSave = () => {
        this.props.unSave(this.state.item.id)
    }

    componentDidMount(){
        //for facebook pixel
        window.fbq('track', 'ViewContent', {content_ids: [this.props.match.params.pId]});

        console.log("product id is", this.props.match.params.pId)
        document.getElementById("searchResult-id").style.display = "none"
        fetch(`${url.url}/api/catalog/product/${this.props.match.params.pId}`)
        .then(res=> {
            if (res.status === 200) return res.json()
            console.log("Failed to fetch product");
            throw new Error("failed to fetch product");
        })
        .then(resJson=>{
            this.setState({
                item : resJson.Items[0],
                loading : false
            })
        })
        .catch(e=>{
            console.error("Error fetching product",e)
        })

        
        fetch(`${url.url}/api/catalog/featured`)
        .then(res => res.json())
        .then(resJson => {
            this.setState({featuredItems : resJson.Items.slice(0,4)})
            this.setState({loading : false})
        })
        
    }



    componentDidUpdate(prevProps){

        //for facebook pixel
        window.fbq('track', 'ViewContent', {content_ids: [this.props.match.params.pId]});

        // console.log("Should change now");
        if(this.props.match.params.pId !== prevProps.match.params.pId){
            console.log("product id is", this.props.match.params.pId)
            this.setState({loading:true})
        fetch(`${url.url}/api/catalog/product/${this.props.match.params.pId}`)
        .then(res=> {
            if (res.status === 200) return res.json()
            console.log("Failed to fetch product");
            throw new Error("failed to fetch product");
        })
        .then(resJson=>{
            this.setState({
                item : resJson.Items[0],
                loading : false
            })
        })
        .catch(e=>{
            console.error("Error fetching product",e)
        })
        }
    }

    render() {
        this.discountPrice = (
            <div className="row mb-2 mb-md-4">
                <div className="col-9 d-flex">
                    <h1 className='pr-2' style={{color: "#93D374"}}>&#8377;{this.state.item.discountedPrice}</h1> 
                    <h1 ><del>&#8377;{this.state.item.price}</del></h1>
                </div>
                <div className="d-flex justify-content-center col-3"><p className="px-3" style={{background: "#90DA7B",borderRadius: "20px"}}>{parseInt((this.state.item.price - this.state.item.discountedPrice)*100/(this.state.item.price))}%</p></div>
            </div>
        )
    
        this.originalPrice = (
            <h1 className="mb-2 mb-md-4">&#8377;{this.state.item.price}</h1>
        )
        return (
            <div>
                {this.state.loading 
                    ? 
                        <div className="loadingDiv">
                            <Spinner className="spinner" animation="border" variant="success"/>
                        </div>
                    :
                <div>
                    <div className="d-none d-md-flex mt-1 mb-2 topNav">
                        <p className="mr-2 text-muted">Shop by Category &gt;</p>
                        <p className="text-capitalize text-muted mr-2">{this.state.item.category} &gt; </p>
                        <p style={{color:"#93D374"}}>{this.state.item.name}</p>
                    </div>
                    <div className="row mb-5">
                        <div className = "col-sm-6">
                            <img className="mainImg rounded"
                                src={this.state.item.imageUrl} alt="Product"
                            />
                        </div>
                        <div className = "col-sm-6 p-3">
                            <div className="row mb-2 mb-md-2">
                                <div className="col-9 pr-0">
                                    <h1>
                                        {this.state.item.name}
                                    </h1>
                                </div>
                                <div className="d-flex justify-content-center col-3 pr-4">
                                    {
                                        JSON.stringify(this.props.user) === "{}" ? null : this.props.saved ? <div role="button" onClick={this.unSave}><img className="center" alt="D" src={unsave}/></div> : <div role="button" onClick={this.like}><img className="center" alt="S" src={heart} /></div>
                                    }
                                </div>
                            </div>
                            <h2 className="mb-2 mb-md-4">{this.state.item.size} {this.state.item.unit}</h2>
                            {this.state.item.isDiscounted ? this.discountPrice : this.originalPrice}
                            <div className="row mb-4 mb-md-2">
                                <div className="col-6 col-md-6">
                                {this.state.item.quantity > 0 && this.props.qtyInCart < this.state.item.quantity
                                    ?
                                        this.props.inCart 
                                            ?
                                            <button className="border-0 rounded p-1" style={{width:"90%", backgroundColor:"#90DA7B"}}>Added<img className="ml-1" alt="" src={check}/></button>
                                            :
                                            <button className="border-0 rounded p-1" style={{width:"90%", backgroundColor:"#90DA7B"}} onClick={this.handleAdd}>
                                                Add to Cart <img src={shoppingCartBtn} alt=""/>
                                            </button>
                                    :
                                        <button className="border border-gray btn btn-light rounded p-1" style={{width:"90%"}} disabled>
                                            Out of Stock
                                        </button>
                                    }
                                </div>
                                <div className="mt-0 col-6 col-md-5" style={{height:"40px"}}>
                                    {this.props.inCart
                                        ?
                                            <QuantityPicker id={this.state.item.PK} productQuantity={this.state.item.quantity} />
                                        :
                                            null
                                    }
                                </div>
                            </div>
                            <p><i>Estimated delivery in 3 to 5 days.</i></p>
                            {/* {this.state.item.quantity > 0 && this.props.qtyInCart < this.state.item.quantity
                                ?
                                <div role="button" className="btn p-1 addBtn border-0" style={{width:"40%", backgroundColor:"#90DA7B"}}>
                                            Add to Cart <img src={shoppingCartBtn}/>
                                </div>
                                :
                                <button className="btn p-1 addBtn" disabled>
                                    Out of Stock
                                </button>
                            }
                            {
                                this.props.inCart 
                                    ? 
                                        <div>
                                            <QuantityPicker id={this.state.item.id} /> 
                                        </div> 
                                    : 
                                    null
                            } */}
                        </div>
                
                    </div>
                    <div className="mb-5 p-5" style={{background: "rgb(205, 212, 205, 0.3)", borderRadius:"5px", marginTop:"80px"}}>
                        <h4 className="mb-4">WHY YOU'LL LOVE IT</h4>
                        <p>{this.state.item.desc}</p>
                    </div>
                    <div className="my-5">
                        <h2 className="text-center mb-4 font-weight-bold" style={{color:"#54B94D"}}>Features</h2>
                        <Features />
                    </div>
                    <div className="my-5">
                        <h2 className="text-center mb-4">Featured Products</h2>
                        <FeaturedContainer items={this.state.featuredItems} loading={this.state.loading}/>
                    </div>
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    return {
        user : state.profile,
        inCart : state.cart.find(item=>item.id === ownProps.match.params.pId) ? true : false,
        qtyInCart : state.cart.find(item=>item.id === ownProps.match.params.pId) ? (state.cart.find(item=>item.id === ownProps.match.params.pId)).qty : 0,
        saved : state.profile.wishlist && state.profile.wishlist.find(item => item.id === ownProps.match.params.id) ? true : false
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart : (item) => dispatch(addtoCart(item)),
        like : (item,id) => dispatch(addToWishlist(item,id)),
        updateQty : (id,qty) => dispatch(updateQty(id,qty)),
        unSave : (id) => dispatch(deleteFromWishlist(id))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProductPage)
