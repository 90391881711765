import React from 'react'

export default function DataDeletion() {
    return (
        <div>
            <h3 className="text-center my-5">Data Deletion Instructions</h3>
            <p className="bg-light w-75 m-auto p-3 p-md-5 text-left">
                Please <a href="#footer">Contact Us</a> if you wish to delete
                your data from our records. We will be happy to assist you.
            </p>
        </div>
    )
}
