const initState = []

const cartReducer = (cart = initState, action) => {
    switch(action.type) {
        case "ADD_ITEM":
            return [
                ...cart,
                action.item
            ]
        case "UPDATE_QTY":
            console.log("inside update qty action", action.id, action.qty);
            
            return cart.map((item) => {
                if (item.id !== action.id) {
                    return item
                }
                else {
                    return {
                        ...item,
                        qty : action.qty
                    }    
                }
            })
        case "DELETE_FROM_CART":
            console.log("inside delete item action", action.id);
            return cart.filter(item => item.id !== action.id)
        case "EMPTY_CART":
            return initState
        default:
            return cart
    }
}

export default cartReducer