import {combineReducers} from 'redux';
import profileReducer from './profileReducer'
import cartReducer from './cartReducer'
import shippingInfoReducer from './shippingInfoReducer'
import currOrderReducer from './currOrder';
import ordersReducer from './ordersReducer';
import { productsReducer } from './productsReducer';
import checkoutReducer from './checkoutReducer';
import { couponsReducer } from './couponsReducer';

const appReducer = combineReducers({
    profile : profileReducer,
    cart : cartReducer,
    shippingInfo : shippingInfoReducer,
    orders : ordersReducer,
    currOrder : currOrderReducer,
    productData : productsReducer,
    checkout : checkoutReducer,
    coupons : couponsReducer
})

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT_USER"){
        state = undefined
    }
    return appReducer(state,action)
}

export default rootReducer;