import React, { Component } from 'react'
import CardforWishlist from './CardforWishlist'
import {connect} from 'react-redux'
import heartIcon from '../../../Icons/heart.svg'

export class Wishlist extends Component {

    render() {

        const items = this.props.wishlist

        return (
            items.length > 0 
                ?
                items.map((item,index)=>(
                    <CardforWishlist key={index} item={item}/>
                ))
                :
                <div className="wishlistMsg border py-3 px-2">
                    <p className="mb-0">No saved items</p>
                    <p className="">Press <img src={heartIcon} alt="S"/> on the product cards</p>
                </div>
            
        )
    }
}
const mapStateToProps = (state) => {
    return {
        wishlist : state.profile.wishlist
    }
}
export default connect(mapStateToProps)(Wishlist)
