import axios from 'axios';
import url from '../../url.json'

export const setSodexoSourceId = (requestId, userId) => {
    return async (dispatch) => {
        
        const res = await axios.put(`${url.url}/api/payment/soxedo/sourceId`, (requestId,userId))
        dispatch({
            type : "SET_SODEXO_SOURCE_ID",
            sourceId : res.data.sourceId
        })
    }
}