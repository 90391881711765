import React from 'react'
import {connect} from 'react-redux'
import  AddrItemClass  from './AddrItemClass';


function AddressList(props) {
    
    let addressList = props.addresses.map((item,index)=> {
            return <AddrItemClass addr={item} isDefault={true} itemId={item.id} idx={index} key={item.id} />
    })

    return (
        <div className="m-2 m-md-3">
            {addressList}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        addresses : state.profile.addresses,
        defaultAddrIdx : state.profile.defaultAddrIdx
    }
}

export default connect(mapStateToProps)(AddressList)
