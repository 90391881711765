import React, { Component } from 'react'
import {connect} from 'react-redux'
import { addtoCart } from '../../../store/actions/cart/addtoCart';
import inr from '../../../Icons/inr.svg'
import cart from '../../../Icons/shopping-cart.svg'
import check from '../../../Icons/check.svg'
import { deleteFromWishlist } from '../../../store/actions/deleteFromWishlist';

export class CardforCart extends Component {

    handleDelete = () => {
        this.props.unSave(this.props.item.id)
    }

    handleAdd = () => {
        this.props.addToCart({...this.props.item , qty: 1})
    }

    render() {
        console.log("Wishlist card props", this.props);
        return (
            <div className="CardForCart p-0 shadow-sm m-2 rounded position-relative">
                <img className="rounded-left" src={this.props.item.imageUrl} alt="Product Img" />
                <div className="CardForCart-body ml-2 p-1 position-relative">
                    <h4 className="mb-1 text-left" style={{maxHeight:"42%", overflow:"hidden", width:"90%"}}>{this.props.item.name}</h4>
                    <h4 className="m-0 text-left">{this.props.item.size} {this.props.item.unit}</h4>
                    <div className="d-flex justify-content-between quantity pr-2">
                        {this.props.inCart 
                            ?
                                <button className="addedBtn">Added<img className="ml-1" style={{height:"70%", width:"20px"}} src={check} alt=""/></button>
                            :
                                <button className="addBtn w-50 h-75" onClick={this.handleAdd} style={{fontSize:'1.1em'}}>Add<img className="ml-1" style={{height:"70%", width:"20px"}} src={cart} alt=""/></button>
                        }
                        <h3 className="mr-2 my-auto">&#8377;{this.props.item.price}</h3>
                    </div>
                </div>
                {/* <h3 >{this.props.item.name}</h3> */}
                <button type="button" className="close" onClick={this.handleDelete} aria-label="Close" style={{position:"absolute", top:"5px", right:"5px"}}>
                    <span aria-hidden="true">&times;</span>
                </button>
                {/* <div role="button" onClick={this.handleDelete} style={{position:"absolute", top:"5px", right:"5px"}}>X</div> */}
                {/* <QuantityPicker id={this.props.item.id}/> */}
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart : (item) => dispatch(addtoCart(item)),
        unSave : (id) => dispatch(deleteFromWishlist(id))
    }
}
const mapStateToProps = (state,ownProps) => {
    return {
        inCart : state.cart.find(item=>item.id === ownProps.item.id) ? true : false
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CardforCart)
