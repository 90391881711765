import store from '../configureStore'
import url from '../../url.json'

export const saveProfile = (fname,lname,email,mobile) => {
    return (dispatch) => {
        //
        fetch(`${url.url}/api/user/profile`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body : JSON.stringify({
                userPK : store.getState().profile.PK,
                fname,
                lname,
                email,
                mobile
            })
        })
            .then(res => {
                if (res.status === 200) return res.json()
                console.log("Failed to update profile");
                throw new Error("failed to update profile");
            })
            .then(resJson => {
                if (resJson.error){
                    return;
                }else{
                    dispatch({
                        type : "SAVE_PROFILE_DETAILS",
                        fname,
                        lname,
                        email,
                        mobile
                    })
                }
            })
            .catch(error => {
                console.log("Error updating profile", error);
            })
    
    }
}