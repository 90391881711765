import React ,{useState, useEffect} from 'react'
import { Form, FormLabel, FormControl, Row, Col, Button, InputGroup, FormText } from 'react-bootstrap';
import {connect} from 'react-redux'
import { addAddress } from '../../../../store/actions/addAddress'
import { v4 as uuidv4 } from 'uuid';
import url from '../../../../url.json'
import { setFullAddr } from '../../../../store/actions/shippingInfo/setAddress';
import { makeDefault } from '../../../../store/actions/makeAddDefault';
import { setNoAddrSelected } from '../../../../store/actions/checkout/setNoAddrSelected';

function AddressForm(props) {

    const [servicable, setServicable] = useState(undefined);

    const [fullName, setFullName] = useState("");
    const [mobile, setMobile] = useState(props.user.mobile);
    const [email, setEmail] = useState(props.user.email);
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [pincode, setPincode] = useState('')
    const [city, setCity] = useState('');
    const [state,setState] = useState('');
    const [loading, setLoading] = useState(false);
    const [isPrimary, setIsPrimary] = useState(true);

    const handleNameChange =(e) => {
        setFullName(e.target.value)
    }
    const handleMobileChange = (e) => {
        setMobile(e.target.value)
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    const handlePinChange = (e) => {
        setPincode(e.target.value)
    }
    const handleLine1Change = (e) => {
        setLine1(e.target.value)
    }
    const handleLine2Change = (e) => {
        setLine2(e.target.value)
    }

    const submitForm = () => { return }

    const handleSubmit = () => {
        if (line1 === '' || mobile.length !== 10 || isNaN(mobile) || pincode.length !== 6 || email === '' || !email.includes("@")){
            if(fullName === ''){
                document.getElementById('addFullName').style.borderColor = 'red'
            }
            if(mobile.length !== 10){
                document.getElementById('addMobile').style.borderColor = 'red'
            }
            if (typeof mobile !== 'number'){
                document.getElementById('addMobile').style.borderColor = 'red'
            }
            if(email === '' || !email.includes("@")){
                document.getElementById('addEmail').style.borderColor = 'red'
            }
            if(line1 === ''){
                document.getElementById('addLine1').style.borderColor = 'red'
            }
            if(city === ''){
                document.getElementById('addCity').style.borderColor = 'red'
            }
            if(state === ''){
                document.getElementById('addState').style.borderColor = 'red'
            }
            if(pincode.length !== 6){
                document.getElementById('addPincode').style.borderColor = 'red'
            }
            // document.getElementById('addErrorMsg').style.display = "inline"
            return
        }
        
        const newAddress = {
            id : uuidv4(),
            fullName,
            mobile,
            email,
            line1,
            line2,
            pincode,
            state,
            city,
            country : "India",
            default : false
        } 

        props.add(newAddress, () => {
            if(isPrimary){
                props.makeAddDefault(newAddress.id)
            } 
        });
        props.setCurrAddr(newAddress)
        props.setNoAddrSelected(false);
        props.close()
    }

    useEffect(()=> {
        if(pincode.length === 0){
            setServicable(undefined)
        }
        else{
            setLoading(true)
            setServicable(undefined)


            //THE BELOW CODE IS THE ACTUAL CODE... PLEASE USE THAT ONCE THE ISSUE WITH FEDEX IS FIXED

            pincode.length === 6 &&
            fetch(`${url.url}/api/delivery/pincode`, {
                method : 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                    },
                body : JSON.stringify({
                    pincode : pincode
                })
            })
            .then(res => {
                if (res.status === 200) return res.json()
                throw new Error("Error validing pincode")
            })
            .then(resJson => {
                if (resJson.pincodeFound){
                    setServicable(1)
                } else {
                    setServicable(0)
                }
                setLoading(false)
            })
            .catch(err => {
                console.log("Error validating pincode   ", err)
                window.alert("Error validating pincode. Try again later.")
            })
        }
    }, [pincode])

    useEffect(()=> {
        props.user.fname && props.user.fname && setFullName(props.user.fname+ " "+props.user.lname);
    },[])

    return (
        <div>
            <Form onSubmit={submitForm}>
                <Row className="py-2">
                    <Col><FormLabel className="float-right">Name</FormLabel></Col>
                    <Col xs={9}><FormControl id="addFullName" className="bg-light w-75" type="text" value={fullName} onChange={handleNameChange}></FormControl></Col>
                </Row>
                <Row className="py-2">
                    <Col><FormLabel className="float-right">Mobile</FormLabel></Col>
                    <Col xs={9}>
                        <InputGroup className="w-75">
                            <InputGroup.Prepend><InputGroup.Text className="border-0 bg-mid-gray">+91</InputGroup.Text></InputGroup.Prepend>
                            <FormControl id="addMobile" className="bg-light" type="text" value={mobile} onChange={handleMobileChange}></FormControl>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="py-2">
                    <Col><FormLabel className="float-right">Email</FormLabel></Col>
                    <Col xs={9}><FormControl id="addEmail" className="bg-light w-75" type="text" value={email} onChange={handleEmailChange}></FormControl></Col>
                </Row>
                <Row className="py-2">
                    <Col><FormLabel className="float-right">Line 1</FormLabel></Col>
                    <Col xs={9}><FormControl id="addLine1" className="bg-light w-75" type="text" onChange={handleLine1Change} placeholder="e.g. 301 Infinity Towers"></FormControl></Col>
                </Row>
                <Row className="py-2">
                    <Col><FormLabel className="float-right">Line 2</FormLabel></Col>
                    <Col xs={9}><FormControl type="text" className="bg-light w-75" onChange={handleLine2Change} placeholder="e.g. Civil Lines"></FormControl></Col>
                </Row>
                <Row className="py-2">
                    <Col><FormLabel className="float-right">Pincode</FormLabel></Col>
                    <Col xs={9}><FormControl id="addPincode" className="bg-light w-75" type="text" value={pincode} onChange={handlePinChange}></FormControl></Col>
                </Row>
                {loading == false || pincode.length !== 6
                    ? 
                        <></> 
                    : 
                        <FormText className="d-flex justify-content-around text-info"> 
                            Checking servicability ...
                        </FormText>
                }
                {servicable === 1 
                    ? 
                        <FormText className="d-flex justify-content-around mr-md-5 text-success"> 
                            Standard (3-5 days) Delivery available
                        </FormText> 
                    : 
                        null
                }

                {servicable === 0 && pincode.length === 6
                    ?
                        <FormText className="d-flex justify-content-around text-danger"> 
                            We are currently not delivering in this area
                        </FormText> 
                    :
                        null
                }
                {/* <Row className="d-flex justify-content-center">
                    {loading === false ? <></> :<p className="text-info"> Checking Serviceability ..</p>}
                </Row>
                <Row className="d-flex justify-content-center">
                    {servicable === 1 ? <></> :<p> We are currently not delivering in this area</p>}
                </Row>
                <Row className="d-flex justify-content-center">
                    {servicable === 0 ? <></> :<p> We are currently not delivering in this area</p>}
                </Row> */}
                <Row className="py-2">
                    <Col><FormLabel className="float-right">City</FormLabel></Col>
                    <Col xs={9}><FormControl className="bg-light w-75" type="text" id="addCity" value={city} onChange={e=>setCity(e.target.value)}></FormControl></Col>
                </Row>
                <Row className="py-2">
                    <Col><FormLabel className="float-right">State</FormLabel></Col>
                    <Col xs={9}><FormControl className="bg-light w-75" type="text" id="addState" value={state} onChange={e=>setState(e.target.value)}></FormControl></Col>
                </Row>
                <Row className="py-2">
                    <Col><FormLabel className="float-right">Country</FormLabel></Col>
                    <Col xs={9}><FormControl className="bg-light w-75" type="text" value="India" readOnly></FormControl></Col>
                </Row>
                <Row className="py-2">
                    <Col><FormLabel className="float-right">Primary Address</FormLabel></Col>
                    <Col><Form.Check type="checkbox" checked={isPrimary} onChange={()=>setIsPrimary(!isPrimary)}></Form.Check></Col>
                </Row>
                <div className="d-flex justify-content-center mt-3">
                    <Button className="bg-transparent border-dark text-dark rounded shadow-sm mr-2" onClick={props.close}>Cancel</Button>
                    <Button className="bg-success border-0 rounded shadow-sm" onClick={handleSubmit}>Save Address</Button>
                </div>
            </Form>           
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user : state.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        add : (newAddress, callback) => dispatch(addAddress(newAddress, callback)),
        setCurrAddr : (addr) => dispatch(setFullAddr(addr)),
        makeAddDefault: (id) => dispatch(makeDefault(id)),
        setNoAddrSelected : (state) => dispatch(setNoAddrSelected(state))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AddressForm)
