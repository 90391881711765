import React from 'react'
import Razorpay from 'razorpay';
import url from '../../url.json'
import axios from 'axios';
import { addRazorpayID } from '../../store/actions/addRazorpayID';
import rpConfig from '../../config/razorpay.json';

export const continueWithRazorpay = (addRazorpayID, initOrder, setLoading) => {

    fetch(`${url.url}/api/payment/createOrder`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body : JSON.stringify({initOrder})
    })
    .then(res => {
        if (res.status === 200) return res.json()
        console.log("Failed to create order");
        throw new Error("failed to create order");
    })
    .then(resJson => {
        if (resJson.error){
            window.alert("Service not available")
            return;
        }else{
            console.log("======razorpay order created========")
            
            //add the resJson.id to the currOrder
            addRazorpayID(resJson.id)
            // console.log(order)
            var options = {
                "key": rpConfig.rp_key, 
                "amount": resJson.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": "INR",
                "name": "The Nature's Bowl",
                "order_id": resJson.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "callback_url": `${url.url}/api/payment/callback`,
                "prefill": {
                    "name": initOrder.address.fullName,
                    "email": initOrder.address.email,
                    "contact": initOrder.address.mobile
                },
                "theme": {
                    "color": "#3399cc"
                }
            };
            var rzp = new window.Razorpay(options);
            rzp.open()
            setLoading(false)
        }
    })
    .catch(error => {
        window.alert("Service not available")
        console.log("Error creating razorpay order", error);
    })
    

    return(
        <div></div>
    )

}
