import React from 'react'
import Addresses from './Addresses'

export default function index() {

    return (
        <div>
            <Addresses />
        </div>
    )
}
