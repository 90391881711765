import React, { Component } from 'react'
import ProductCard from './ProductCard'
import './ProductCard.css'
export class Products extends Component {

    render() {
        const products = this.props.items.map((product,index) =>
            <ProductCard product= {{...product, id : product.PK}} key={index} />
            )

        return (
            <div className="productContainer py-md-3" >
                {/* {this.props.loading 
                    ?
                        <div><Spinner animation="border" variant="success" /></div>
                    :
                        null } */}
                {products}
                {/* {products.length > 0 ? products : <ProductCard product={{}}/>} */}
            </div>
        )
    }
}

export default Products
