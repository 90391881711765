import url from  '../url.json'

export const updateCartInfo = (cart, user) => {
    fetch(`${url.url}/api/cart/${user.PK}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body : JSON.stringify({
            userId: user.PK,
            email: user.email,
            phone: user.mobile,
            name: `${user.fname} + ${user.lname}`,
            cart: cart.map(item => {
                const { id, PK, size, unit, qty, price, discountedPrice, name } = item;
                return { id, PK, size, unit, qty, price, discountedPrice, name }
            })
        }) 
    })
    .then(res => res.json())
    .then();
    return;
}