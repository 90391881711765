import React from 'react'

export default function Privacy() {
    return (
        <div>
            <h3 className="text-center my-5">Our Privacy Policy</h3>
            <p className="bg-light w-75 m-auto p-3 p-md-5 text-left">
            This Privacy Policy hereinafter referred to as “Policy“ describes the procedures on the collection, use, disclosure and protection of your information when you use our website located at www.thenaturesbowl.com or the “The Nature’s Bowl” which are made available and are completely owned and operated by “TNB Foods Private Limited” (“The Nature’s Bowl”, “Company”, “we”, “us” and “our”), a private company limited by shares and  established under the Indian Companies act , 2013 and other laws of India having its registered office at H. No 1121, P. No90, Bandhu Gruh Nirman Sanstha, Sambhaji Nagar, Lokhande L/O, Nagpur, Maharashtra-440022 where such expression shall, unless repugnant to the context thereof, be deemed to include its respective representatives, administrators, employees, directors, officers, agents and their successors and assigns. <br /><br />
            That, for the purposes of this policy, wherever the context so requires,<br /><br />
            
            (i) The terms ‘You’ and ‘User’ shall mean any legal person or entity accessing or using the services provided on this Website, who is competent to enter into binding contracts, as per the provisions of the Indian Contract Act, 1872 and any other applicable Indian Laws.<br /><br />
            (ii) The terms ‘We’, ‘Us’ and ‘Our’ shall mean the Website and/or the Company, as the context so requires.<br /><br />
            (iii) The terms ‘Party’ and ‘Parties’ shall respectively be used to refer to the user and the company individually or collectively as the context so requires.<br /><br />
            That, the User unequivocally agrees that this Policy and the aforementioned Terms constitute a legally binding agreement between the User and the Company, and that the User shall be subject to the rules, guidelines, policies, terms, and conditions applicable to any service that is provided by the Website, and that the same shall be deemed to be incorporated into the Terms, and shall be treated as part and parcel of the same. The User acknowledges and agrees that no signature or express act is required to make these Terms and the Policy binding on the User, and that the User’s act of visiting any part of the Website constitutes the User’s full and final acceptance of the Policy and the aforementioned Terms.<br /><br />
            <b>COLLECTION OF INFORMATION (PERSONAL AND OTHER)</b><br /><br />
            (i) That, the user hereby expressly agrees and acknowledges that The Nature’s Bowl collects and stores the User’s personal information, which is provided by the User from time to time on The Nature’s Bowl Website, including but not limited to the User’s user name, passwords, email address, name, address, age, date of birth, sex, nationality, shopping preferences, browsing history, etc., as well as any images or other information uploaded/published by the User on The Nature’s Bowl Website.
            <br /><br />(ii)That, the user is aware that The Nature’s Bowl may automatically track certain information about the User based upon the User’s IP address and the User’s behaviour on The Nature’s Bowl Website, and the User expressly consents to the same. The User is aware that this information is used to do internal research on user demographics, interests, and behaviour, to enable The Nature’s Bowl Website to better understand, and cater to the interests of its users. The User is expressly made aware that such information may include the URL that the User visited prior to accessing The Nature’s Bowl Website, the URL which the User subsequently visits (whether or not these URLs form a part of the Website), the User’s computer and web browser information, the User’s IP address, etc.
            <br /><br />(iii) That, if the User chooses to purchase products / services from The Nature’s Bowl Website, the User consents to allowing The Nature’s Bowl Website to collect information about the User’s buying behaviour and trends.
            <br /><br />(iv) That, if the User chooses to post messages / reviews / feedback anywhere on The Nature’s Bowl Website, including but not limited to message boards, chat rooms, other message areas, etc., the User is aware that any and all such information provided / uploaded will be collected and stored by The Nature’s Bowl for an indefinite period of time, and that such retained information may be used to resolve disputes, provide customer support, troubleshoot problems, etc., and that such information, if requested, may be provided to judicial or governmental authorities of requisite jurisdiction, or otherwise used by The Nature’s Bowl Website as permitted by applicable laws.
            <br /><br />(v)That, if the User is aware that any and all information pertaining to the User collected by The Nature’s Bowl, whether or not directly provided by the User to The Nature’s Bowl                                                                                         Website, including but not limited to personal correspondence such as emails or letters, feedback from other users or third parties regarding the User’s activities or postings on the Website, etc., may be collected and complied by The Nature’s Bowl Website into a file/folder specifically created for / allotted to the User, and the User hereby expressly consents to the same.
            <br /><br />(vi) That, the User is aware that while he/she can browse some sections of The Nature’s Bowl Website without being a registered user, certain activities (such as placing an order) require the User to provide valid personal information to The Nature’s Bowl Website for the purpose of registration. The User is aware that the contact information provided to The Nature’s Bowl Website may be used to send the User offers and promotions, whether or not based on the User’s previous orders and interests, and the User hereby expressly consents to receiving the same.
            <br /><br />(vii) That, the user is aware that The Nature’s Bowl Website may occasionally request the User to complete optional online surveys. These surveys may require the User to provide contact information and demographic information (like zip code, age, income bracket, sex, etc.). The User is aware that this data to is used to customise the Website for the benefit of the User, and providing all users of the Website with products/services/content that The Nature’s Bowl Website believes they might be interested in availing of, and also to display content according to the User’s preferences.
            <br /><br />(viii) That, the user is further aware that The Nature’s Bowl Website may occasionally request the User to write reviews for products/services purchased/availed of by the User from The Nature’s Bowl Website, and also reviews for the various sellers listing their products/services on The Nature’s Bowl Website. The User is aware that such reviews will help other users of the website make prudent and correct purchases, and also help The Nature’s Bowl Website remove sellers whose products are unsatisfactory in any way, and the User hereby expressly authorises The Nature’s Bowl Website to publish any and all reviews written by the User on The Nature’s Bowl Website, along with the User’s name and certain contact details, for the benefit and use of other Users of The Nature’s Bowl Website.
            <br /><br /><br /><b>COOKIES</b><br /><br />
            That, the User is aware that a ‘Cookie’ is a small piece of information stored by a web server on a web browser so it can later be traced back from that particular browser, and that cookies are useful for enabling the browser to remember information specific to a given user, including but not limited to a User’s login identification, password, etc. The User is aware that The Nature’s Bowl Website places both permanent and temporary cookies in the User’s computer's hard drive and web browser, and does hereby expressly consent to the same.That, the User is further aware that The Nature’s Bowl Website uses data collection devices such as cookies on certain pages of the Website to help analyse web page flow, measure promotional effectiveness, and promote trust and safety, and that certain features of the Website are only available through the use of such cookies. 
            
            <br /><br /><br /><b>THE DIVULGING /SHARING OF PERSONAL INFORMATION:</b><br /><br />
            That, the User is aware that The Nature’s Bowl may share the User’s personal information with other corporate entities and affiliates to help detect and prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts to prevent abuse of the Website’s services; and to facilitate joint or co-branded services, where such services are provided by more than one corporate entity.That, the User is aware that The Nature’s Bowl may disclose personal information if required to do so by law. The Nature’s Bowl may also disclose the User’s personal information to third party rights owners, or other third parties if it believes that such disclosure is reasonably necessary to enforce the Terms or Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of its users, or the general public.
            <br /><br /><br /><b>SECURITY</b><br /><br />
            Transactions on The Nature’s Bowl Website are secure and protected. Any information entered by the User when transacting on The Nature’s Bowl is encrypted to protect the User against unintentional disclosure to third parties. The User’s credit and debit card information is not received, stored by or retained by The Nature’s Bowl in any manner. This information is supplied by the User directly to the relevant payment gateway which is authorized to handle the information provided, and is compliant with the regulations and requirements of various banks and institutions and payment franchisees that it is associated with.
            <br /><br /><br /><b>THIRD PARTY ADVERTISEMENTS / PROMOTIONS</b><br /><br />
            The Nature’s Bowl uses third-party advertising companies to serve ads to the users of the Website. The User is aware that The Nature’s Bowl may use information relating to the User’s visits to the Website and other websites in order to provide customised advertisements to the User. Furthermore, the Website may contain links to other websites that may collect personally identifiable information about the User.The Nature’s Bowl is not responsible for the privacy practices or the content of any of the aforementioned linked websites, and the User expressly acknowledges the same and agrees that any and all risks associated will be borne entirely by the User.
            <br /><br /><br /><b>PAYMENT CANCELLATION AND REFUND POLICY</b><br /><br />
            You hereby agree that, the refunds will be made to you in the manner which is preferred by the company and that providing the refund amount is completely at the discretion of the company. All fees and charges are quoted and billed in maximum retail price (MRP) unless otherwise noted. The Nature’s Bowl may take any reasonable action to validate your payment and registration information, and collect all payments due. You agree to pay all attorney and collection fees arising from any efforts to collect any past due amounts from you, to the extent allowed by law. Service fees are due at the time of order or on the day of renewal. All fees must be paid in full.
            <br /><br /><br /><b>INTELLECTUAL PROPERTY RIGHTS</b><br /><br />
            All content such as graphics, logos, text, button icons, images, audio clips digital downloads, data compilations and software included in or made available through The Nature’s Bowl is the property of The Nature’s Bowl and its content suppliers and the trademark may not used in connection with any product or service in any manner that is likely to create confusion among the customers.
            <br /><br /><br /><b>APPLICABLE LAW AND DISPUTE RESOLUTION</b><br /><br />
            These User Terms are subject to the laws of India. Any dispute, claim or controversy arising out of or relating to these User Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Site, the Service or the Application (collectively, “Dispute. In the event that the Parties are unable to amicably resolve a dispute mutually, said dispute will be referred to arbitration by a sole arbitrator to be appointed by The Nature’s Bowl, and the award passed by such sole arbitrator will be valid and binding on both Parties. That, the Parties expressly agree that the Terms, Policy and any other agreements entered into between the Parties are governed by the laws, rules and regulations of India, and that the Courts at Nagpur, Maharashtra, shall have the exclusive jurisdiction over any disputes arising between the Parties.
            <br /><br /><br /><b>ASSIGNMENT</b><br /><br />
            Under no circumstances you shall assign your rights under these User Terms without prior written approval of ‘The Nature’s Bowl’. ‘The Nature’s Bowl’ can however assign its rights under the User Terms to any affiliate.
            </p>
        </div>
    )
}
