import axios from "axios";
import url from '../url.json'

export const fetchOrdersAPI = async (userId) => {
    let orders = await axios.get(`${url.url}/api/orders/${userId}`)
    // console.log(orders);
    if(orders.data.error !== true && orders.status === 200){
        return orders.data.orders.Items
    }
    else {
        console.log("error fetching orders from db")
        return
    }




    // fetch(`http://localhost:4000/api/orders/${userId}`, {
    //     method : "GET",
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    // })
    // .then(res => {
    //     if (res.status === 200) return res.json()
    //     console.log("Failed to fetch orders");
    //     throw new Error("failed to fetch orders");
    // })
    // .then(resJson => {
    //     if (resJson.error !== true){
    //         // console.log("orders",resJson.orders.Items)
    //         // store.dispatch(setUserOrders(resJson.orders.Items))
    //         return resJson.orders.Items
    //     }else {
    //         console.log("error saving new order to db")
    //         return
    //     }
    // })
    // .catch(error => {
    //     console.log("Error fetching orders", error);
    //     return
    // })
    
}