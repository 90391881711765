import React, { Component, useEffect } from 'react'
import { loginUser } from '../store/actions/loginUser';
import { connect } from 'react-redux';
import { fetchOrdersAPI } from '../apis/fetchOrdersAPI';
import { setUserOrders } from '../store/actions/setUserOrder';
import { useHistory } from "react-router-dom";
import url from '../url.json'
import { getCoupons } from '../store/actions/coupons/getCoupons';

function Auth(props) {
    let history = useHistory();

    useEffect(()=>{
        fetch(`${url.url}/api/auth/cookie/profile` , {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true
            }
        })
        .then(res=>{
            if (res.status === 200) return res.json();
            console.log("Failed to authenticate");
            throw new Error("failed to authenticate user");
        })
        .then(async (resJson) => {
            console.log("This is the logged in user", resJson);
            if (resJson.authenticated === true){
                props.loginUser(resJson.user);
                let orders = await fetchOrdersAPI(resJson.user.PK)
                props.setOrders(orders.filter(item => item.status !== 'pending'));
                history.push('/home')
                
            }else{
                // window.open('/home', "_self")
                history.push('/home')
            }
        })
        .catch(error => {
            console.log("Error authenticating user", error);
        })

    },[])
    // window.location.replace("/")

        return (
            <div>
                
            </div>
        )
}


const mapDispatchToProps = (dispatch) => {
    return {
      loginUser : (user) => dispatch(loginUser(user)),
      setOrders : (orders) => dispatch(setUserOrders(orders))
    }
  }

export default connect(null,mapDispatchToProps)(Auth)
