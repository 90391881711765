import React, { useState, useEffect } from 'react'
import Accordion from './Accordion'
import Summary from './Summary'
import {connect} from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import short_uuid from 'short-uuid'
import { createOrder } from '../../store/actions/createOrder'
import PlainHeader from '../Header/PlainHeader'
import url from '../../url.json'
import axios from 'axios'
import {continueWithRazorpay} from './razorpay';
import { addRazorpayID } from '../../store/actions/addRazorpayID';
import { addCouponToCurrOrder } from '../../store/actions/addCouponToCurrOrder';
import { useHistory } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import { setError } from '../../store/actions/checkout/setError';
import { setNoAddrSelected } from '../../store/actions/checkout/setNoAddrSelected';
import { setEmptyCart } from '../../store/actions/checkout/setEmptyCart';
import { setDelCharge } from '../../store/actions/checkout/setDelCharge';
import { setDiscount } from '../../store/actions/checkout/setDiscount';
import { setProdUnavailable } from '../../store/actions/checkout/setProdUnavailable';
import { setCoupon } from '../../store/actions/checkout/setCoupon';
import {setCouponState} from '../../store/actions/checkout/setCouponState';
import {setCouponMsg} from '../../store/actions/checkout/setCouponMsg'
import { isArray } from 'jquery';
import { getCoupons } from '../../store/actions/coupons/getCoupons';
import { setFullAddr } from '../../store/actions/shippingInfo/setAddress';
import { continueWithSodexo } from './sodexo';
import { setPaymentStatus } from '../../store/actions/checkout/setPaymentStatus';




function Checkout(props)  {

    const [captchaStatus, setCaptchaStatus] = useState(0)
    const [servicable, setServicable] = useState(undefined);
    const [showCODModal, setShowCODModal] = useState(false)
    const [loading, setLoading] = useState(false);

    let history = useHistory();

    const {payment_failure} = props.match.params;
    payment_failure && props.setPaymentStatus(payment_failure);

    const handleApplyCoupon = async () => {
        if(props.coupon !== ""){
            props.setCouponState('')
            props.setCouponMsg("")
            props.addCouponToCurrOrder(props.coupon)

            //here allCoupons is the public coupon inventory and coupon is the user selected/entered coupon

            let couponData = props.allCoupons.find(cpn=>cpn.code === props.coupon)

            //fetching coupondata from the server (USECASE : private coupon)
            if(!couponData){
                try {
                    const result = await axios.get(`${url.url}/api/coupons/${props.coupon.toUpperCase()}`)
                    couponData = result.data
                }catch(error){
                    props.setCouponState(0)
                    props.setCouponMsg("Invalid Coupon Code")
                } 
            }
            //we have the coupondata now, proceed from here
            const numTimesUsed = props.user.coupons && props.user.coupons.find(cpn=> cpn.code === couponData.code) ? props.user.coupons.find(cpn=> cpn.code === couponData.code).timesUsed : undefined
            const canUse = numTimesUsed ? numTimesUsed < couponData.couponLimit : true;
            
            if(!canUse){
                props.setCouponState(0)
                props.setDiscount(0)
                props.setCouponMsg(`This coupon can be only used ${couponData.couponLimit} times`)
                return
            }
            if (couponData && props.subTotal >= couponData.minCart ){
                if(couponData.discountType === "percentage"){
                    const discVal = props.subTotal*(couponData.amount/100)
                    console.log("disc calculated", discVal)
                    // props.setDiscount(min(discVal,couponData.maxDisc))
                    if(couponData.maxDisc < discVal){
                        props.setCouponState(2) // 2 for info
                        props.setCouponMsg(`Max discount limit Rs.${couponData.maxDisc}`)
                        props.setDiscount(parseFloat(couponData.maxDisc))
                        
                    }else{
                        props.setDiscount(parseFloat(discVal))
                        props.setCouponState(1)
                    }
                }else{ //for fixed discounts
                        props.setDiscount(parseFloat(couponData.amount))
                        props.setCouponState(1)
                }
            }else{
                props.setCouponState(0)
                props.setDiscount(0)
                props.setCouponMsg(`Minimum cart value should be ${couponData.minCart}`)
            }
        }else{
            props.setDiscount(0)
            props.setCouponState(undefined)
        }
    }

    useEffect(()=>{
        handleApplyCoupon();
    },[props.coupon])
    // const changePaymentMode = (e) => {
    //     console.log("select payment mode", e.target.value)
    //     setPaymentMode(e.target.value)
    // }

    useEffect(()=>{
        props.setEmptyCart(false);
        props.getCoupons();
    },[])

    useEffect(()=>{
        if (props.subTotal < 799){
            props.paymentMode === "POSTPAID" ? props.setDelCharge(160) : props.setDelCharge(80)
        }
        else{
            props.paymentMode === "POSTPAID" ? props.setDelCharge(80) :  props.setDelCharge(0)
        }
    },[props.subTotal,props.paymentMode]) 


    

    const onCheckoutPress = async () => {
        // setProdUnavailable(false);

        // empty cart check
        if(props.items.length < 1){
            console.log("Cart empty");
            props.setEmptyCart(true)
            return
        }

        if(servicable === 0){

        }

        //if guest user -> validating address fields
        if(JSON.stringify(props.user) === "{}"){
            const {fullName, mobile, email, line1, pincode, city, state} = props.addr;
            // if (typeof fullName === 'undefined' || fullName === "" || typeof mobile === 'undefined' || isNaN(mobile) || mobile.length !== 10 || typeof line1 === 'undefined' || line1 === "" || typeof pincode === 'undefined' || pincode.length !== 6 || isNaN(pincode)){
                if (typeof fullName === 'undefined' || fullName === ""){
                    document.getElementById("addFullName").style.borderColor = 'red';
                    props.setError(true)
                    return
                }
                if (typeof mobile === 'undefined' || isNaN(mobile) || mobile.length !== 10){
                    document.getElementById("addMobile").style.borderColor = 'red';
                    props.setError(true)
                    return
                }

                if (typeof email === 'undefined' || !email.includes("@")){
                    document.getElementById("addEmail").style.borderColor = 'red';
                    props.setError(true)
                    return
                }

                if (typeof line1 === 'undefined' || line1 === ""){
                    document.getElementById("addLine1").style.borderColor = 'red';
                    props.setError(true)
                    return
                }

                if (typeof pincode === 'undefined' || pincode.length !== 6 || isNaN(pincode) || servicable === 0){
                    document.getElementById("addPincode").style.borderColor = 'red';
                    props.setError(true)
                    return
                }
                if (typeof city === 'undefined' || city === ""){
                    document.getElementById("addCity").style.borderColor = 'red';
                    props.setError(true)
                    return
                }
                if (typeof state === 'undefined' || state === "" ){
                    document.getElementById("addState").style.borderColor = 'red';
                    props.setError(true)
                    return
                }

        }else{
            //if logged in but no address selected
            if (JSON.stringify(props.addr) === "{}"){
                props.setNoAddrSelected(true)
                return
            }
        }

        //if any of the products is suddenly out of stock send and error
        var i;
        for (i=0; i<props.items.length; i++){
            const resp = await axios.get(`${url.url}/api/catalog/check/${props.items[i].PK}/${props.items[i].qty}`)
            if(resp.data.avail === false){
                props.setProdUnavailable(true);
                return
            }
        }
        
        if(props.paymentMode === "POSTPAID"){
            if(captchaStatus === 0){
                document.getElementById("captchaWarning").style.display = "block"
                document.getElementById("captcha").scrollIntoView({ behavior: 'smooth', block: 'center' })
                return
            }
        }

        /*
        if(props.paymentMode === "SODEXO" && !['phone','card'].includes(props.sodexoMode)){
                document.getElementById("sodexoModeError").style.display = 'block';
                document.getElementById("sodexoMode").scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
        */

        //if all is fine, create an draft order
        const orderId = short_uuid.generate()
        const initOrder = {
            orderId : orderId,
            items : props.items,
            total : props.total,
            subTotal : props.subTotal,
            discount : props.discount, 
            delCharge : props.delCharge,
            paymentMethod : props.paymentMode,
            sodexoMode : props.sodexoMode,
            userId : JSON.stringify(props.user) === "{}" ? "guest" : props.user.PK,
            address : props.addr,
            createdAt : new Date()
        }

        // initOrder.orderId = orderId;
        // initOrder.items = props.items;
        // initOrder.total = total;


        //creating the order in redux "currOrder"
        proceedToPayment(initOrder);
        // if(props.paymentMode === "POSTPAID"){
            
        // }else{
        //     //hand over to razorpay
        //     props.createOrder(initOrder, () => continueWithRazorpay(props.addRazorpayId, initOrder))
        // }
        
    }
    const proceedToPayment = (order) => {
        setLoading(true);
        switch(props.paymentMode){
            case 'POSTPAID':
                props.createOrder(order, () => setShowCODModal(true));
                break;
            case 'PREPAID':
                props.createOrder(order, () => continueWithRazorpay(props.addRazorpayId, order, setLoading));
                break;
            case 'SODEXO':
                props.createOrder(order, () => continueWithSodexo(order, props.user.sodexoSourceId))
                break;
            default:
                
        }
    }
    const confirmCODOrder = () => {
        history.push(`/orderConfirmation?payment_mode=postpaid`)
    }

    const closeCODModal = () => {
        setShowCODModal(false)
        setLoading(false)
    }
    return (
        <div>
            <Modal show={showCODModal} onHide={closeCODModal} backdrop="static" keyboard={false} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Order Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Press <strong>Confirm</strong> to complete your Cash on Delivery purchase
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeCODModal}>
                        Go Back
                    </Button>
                    <Button variant="success" onClick={confirmCODOrder}>Confirm</Button>
                </Modal.Footer>
            </Modal>
            <PlainHeader />
            <div className="p-3 p-md-5" style={{backgroundColor:"#E5E5E5"}}>
                <div className="row px-3 px-md-5 mx-md-5">
                    <div className="col-xl-8">
                        <Accordion servicable={servicable} setServicable={setServicable}
                             applyCoupon={handleApplyCoupon}
                             setCaptchaStatus={setCaptchaStatus}
                        />
                    </div>
                    <div className="col-xl-4"> 
                        <Summary onCheckoutPress={onCheckoutPress} payment_failure={payment_failure} loading={loading}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        addr : state.shippingInfo,
        items : state.cart,
        user : state.profile,
        authenticated : JSON.stringify(state.profile) === "{}" ? false : true,
        order : state.currOrder,
        error : state.checkout.error,
        total : state.checkout.total,
        subTotal : state.checkout.subTotal,
        delCharge : state.checkout.delCharge,
        discount : state.checkout.discount,
        paymentMode : state.checkout.paymentMode,
        allCoupons : state.coupons,
        coupon : state.checkout.coupon,
        sodexoMode : state.checkout.sodexoMode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createOrder : (order, cb) => dispatch(createOrder(order, cb)),
        addRazorpayId : (id) => dispatch(addRazorpayID(id)),
        addCouponToCurrOrder : (coupon) => dispatch(addCouponToCurrOrder(coupon)),
        setError : (errorState) => dispatch(setError(errorState)),
        setProdUnavailable : (errorState) => dispatch(setProdUnavailable(errorState)),
        setNoAddrSelected : (state) => dispatch(setNoAddrSelected(state)),
        setEmptyCart : (state) => dispatch(setEmptyCart(state)),
        setDelCharge : (amt) => dispatch(setDelCharge(amt)),
        setDiscount : (amt) => dispatch(setDiscount(amt)),
        setCoupon : (cpn) => dispatch(setCoupon(cpn)),
        setCouponState : (state) => dispatch(setCouponState(state)),
        setCouponMsg : (msg) => dispatch(setCouponMsg(msg)),
        getCoupons : () => dispatch(getCoupons()),
        resetShippingInfo : () => dispatch(setFullAddr({})),
        setPaymentStatus : (status) => dispatch(setPaymentStatus(status))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Checkout)
