import React, {useEffect, useState, useRef} from 'react'
import Coupons from './Coupons'
import { Row, Col, Overlay, OverlayTrigger, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setTotal } from '../../store/actions/checkout/setTotal'
import { setSubTotal } from '../../store/actions/checkout/setSubTotal'
import { setPaymentMode } from '../../store/actions/checkout/setPaymentMode'
import { Alert } from 'bootstrap'

function Summary(props) {

    

    const handleCheckoutPress = () => {
        props.onCheckoutPress();
    }
    useEffect(()=> {
        props.setSubTotal(props.cart.reduce((acc, item)=>{
            return acc + (item.qty*item.discountedPrice)
        }, 0))
    })


    useEffect(()=> {
        props.setTotal(props.subTotal + props.delCharge - props.discount)
    })

    useEffect(()=>{
        props.paymentStatus === "failure" && document.getElementById('alert').scrollIntoView({ behavior: 'smooth' });
    }, [props.paymentStatus])
    return (
        <div className="my-3 p-4 bg-white shadow-sm">
            {/* <Coupons coupon={props.coupon} setCoupon={props.setCoupon} applyCoupon={props.applyCoupon} couponState={props.couponState} couponMsg={props.couponMsg} subTotal={props.subTotal}/> */}
            <div className="mt-4 mb-3 pb-3 border-bottom">
                <h4 className="mb-5">Order Summary</h4>
                <Row>
                    <Col xs={6}><h5>SubTotal</h5></Col>
                    <Col xs={6}><h5 className="mb-0 float-right">&#8377;{props.subTotal.toFixed(2)}</h5></Col>
                </Row>
                <Row>
                    <Col xs={6}><h5>Discount</h5></Col>
                    <Col xs={6}><div className="mb-0 float-right">{props.discount === 0 ?  <h5 className="mb-0">&#8377;0</h5> : <h5 className="text-success mb-0">-&#8377;{props.discount.toFixed(2)}</h5>}</div></Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <h5 className="d-flex">Delivery Charge
                            {/* <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                &#9432;
                            </OverlayTrigger> */}

                            {/* <OverlayTrigger
                                trigger="click"
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Popover id={`popover-positioned-bottom`}>
                                    <Popover.Title as="h3">Fee Breakdown</Popover.Title>
                                    <Popover.Content>
                                        This is it
                                    </Popover.Content>
                                    </Popover>
                                }
                            >&#9432;
                            </OverlayTrigger> */}

                            
                        </h5>
                        { props.paymentMode === "PREPAID" ? 
                            <p> { props.subTotal < 799 ? <i className="text-info">(shop for &#8377;{799-props.subTotal} more for free delivery)</i> : null } </p> 
                            :
                            <u role="button" className="text-success" onClick={()=>props.setPaymentMode("PREPAID")}>Save &#8377;80 by paying online</u> 
                        }
                    </Col>
                    <Col xs={4}>
                        <div className="mb-0 float-right">
                            {props.delCharge === 0 ? <h5 className="text-success mb-0">
                                FREE
                            </h5> 
                            :
                            <h5>
                                &#8377;{props.delCharge}
                            </h5>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                <Row>
                    <Col xs={6}><h4 className="font-weight-bold">Total</h4></Col>
                    <Col xs={6}><h4 className="mb-0 font-weight-bold float-right">&#8377;{props.total.toFixed(2)}</h4></Col>
                </Row>
            </div>
            {props.error 
                ? 
                    <div className="alert alert-danger my-2" role="alert">
                        Correct highlighted fields for shipping details
                    </div>
                :
                    null
            }
            {props.emptyCart 
                ? 
                    <div className="alert alert-danger my-2" role="alert">
                        Your cart is empty, please go back and add some items
                    </div>
                :
                    null
            }
            {props.noAddrSelected 
                ? 
                    <div className="alert alert-danger my-2" role="alert">
                        Select/add an address
                    </div>
                :
                    null
            }
            {props.paymentStatus && props.paymentStatus === "failure"
                ?
                    <div id='alert' className="alert alert-danger my-2" role="alert">
                        Your payment failed. Please try again!
                    </div>
                :
                    null
            }
            {props.prodUnavailable 
                ? 
                    <div className="alert alert-danger my-2" role="alert">
                        One of the products in cart is either completely or partially out of stock
                    </div>
                :
                    null
            }         
            <button className="border-0 w-100 mt-4 p-2 rounded" style={{backgroundColor:"#93D374"}} onClick={handleCheckoutPress}>
            
                {props.paymentMode === "PREPAID" || props.paymentMode === "SODEXO" ?
                    "Continue Payment"
                :
                    "Confirm Order"
                }
                {props.loading ? <span className="spinner-grow spinner-grow-sm ml-2 mb-1" role="status" aria-hidden="true"></span> : null}
                </button>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        cart : state.cart,
        error : state.checkout.error,
        noAddrSelected : state.checkout.noAddrSelected,
        emptyCart : state.checkout.emptyCart,
        total : state.checkout.total,
        subTotal : state.checkout.subTotal,
        delCharge : state.checkout.delCharge,
        discount : state.checkout.discount,
        prodUnavailable : state.checkout.prodUnavailable,
        paymentMode : state.checkout.paymentMode,
        paymentStatus : state.checkout.paymentStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTotal : (amt) => dispatch(setTotal(amt)),
        setSubTotal : (amt) => dispatch(setSubTotal(amt)),
        setPaymentMode : (mode) => dispatch(setPaymentMode(mode))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Summary)