import axios from 'axios';
import url from '../../url.json'

export const loadProductData = () => {
    return(dispatch) => {
        axios.get(`${url.url}/api/catalog/searchData`)
        .then(res => {
            const data = {}
            res.data.result.map(item=>{
                return data[item.PK] = item.name+" "+item.size+item.unit
            })
            dispatch({
                type : "LOAD_DATA",
                data
            })
        })
        .catch(e => console.log(e))
        
    }
}