import React, { useEffect } from 'react';
import QuantityPicker from '../../QuantityPicker';
import { addtoCart } from '../../../store/actions/cart/addtoCart';
import {connect} from 'react-redux'
import {addToWishlist} from '../../../store/actions/addToWishlist';
import { updateQty } from '../../../store/actions/cart/updateQty';
import './ProductCard.css'
import { NavLink } from 'react-router-dom';
import heart from '../../../Icons/heart.svg';
import shoppingCartBtn from '../../../Icons/shopping-cart-btn.svg';
import { Row, Col } from 'react-bootstrap';
import { deleteFromWishlist } from '../../../store/actions/deleteFromWishlist';
import unsave from '../../../Icons/unsave.svg'
import url from '../../../url.json'

export function ProductCard (props) {


    const addBtn = () => {
        //for facebook pixel
        window.fbq('track', 'AddToCart', {content_ids: [ props.product.id]});

        // console.log("add to cart clicked",  props.product)
         props.addToCart({...props.product, qty : 1})
    }

    const like = () => {
        //for facebook pixel
        window.fbq('track', 'AddToWishlist', {content_ids: [props.product.id]});
        props.like(props.product,props.user.PK);
    }

    const unSave = () => {
        props.unSave(props.product.id)
    }

    const discountPrice = (
        <div className="row position-relative">
            <div className="col col-md-8 d-flex">
                <h3 className='pr-1 pr-md-2 mb-0' style={{color: "#93D374"}}>&#8377; {props.product.discountedPrice}</h3> 
                <h3 className="mb-0"><del>&#8377; {props.product.price}</del></h3>
            </div>
            {/* <div className="col-3 col-md-4"><p className="float-right px-1 px-md-3 mb-0" style={{background: "#90DA7B",borderRadius: "20px"}}>{parseInt((props.product.price - props.product.discountedPrice)*100/(props.product.price))}%</p></div> */}
            <h6 className="float-right px-1 px-md-2 mb-0 discount" style={{background: "#90DA7B",borderRadius: "5px"}}>-{parseInt((props.product.price - props.product.discountedPrice)*100/(props.product.price))}%</h6>
        </div>
    )

    const originalPrice = (
        <h3 className="mb-0">&#8377; {props.product.price}</h3>
    )

    const productPageLink = "/home/product/"+props.product.id

    // console.log("Product card store",store.getState())
    return (
        <div className="card border-0 productCard">
            <NavLink className="m-0 p-0 imgCardDiv" to={productPageLink}>
                <img className="card-img-top rounded"
                    src={props.product.imageUrl} alt="Product"
                />
                {/* <img className="card-img-top rounded"
                    src="https://images-tnb.s3.ap-south-1.amazonaws.com/coming-soon/Product.png" alt="Product"
                /> */}
            </NavLink>
            <div className="cardBody">
                <Row className="cardTitle">
                    <Col xs={9} md={9} className="pr-0">
                        <h6 className="m-0">
                            <NavLink className="text-dark" to={productPageLink}>
                                <h4>{props.product.name}</h4>
                            </NavLink>
                        </h6>
                    </Col>
                    <Col xs={3} md={3} className="p-0">
                        { JSON.stringify(props.user) !== "{}" && props.saved ? <div role="button" onClick={unSave}><img className="float-right mr-3" alt="D" src={unsave}/></div> : null}
                        { JSON.stringify(props.user) !== "{}" && props.saved === false ? <div role="button" onClick={like}><img className="float-right mr-3" alt="S" src={heart}/></div>: null}
                        {/* <div role="button" onClick={like}><img className="float-right mr-3" src={heart}/></div> */}
                    </Col>
                </Row>
                <h4 className="cardProdSize mb-2">{props.product.size} {props.product.unit}</h4>
                {props.product.isDiscounted 
                    ?
                    <div className="row position-relative">
                        <div className="col col-md-8 d-flex">
                            <h3 className='pr-1 pr-md-2 mb-0' style={{color: "#93D374"}}>&#8377; {props.product.discountedPrice}</h3> 
                            <h3 className="mb-0"><del>&#8377; {props.product.price}</del></h3>
                        </div>
                        {/* <div className="col-3 col-md-4"><p className="float-right px-1 px-md-3 mb-0" style={{background: "#90DA7B",borderRadius: "20px"}}>{parseInt((props.product.price - props.product.discountedPrice)*100/(props.product.price))}%</p></div> */}
                        <h6 className="float-right px-1 px-md-2 mb-0 discount" style={{background: "#90DA7B",borderRadius: "5px"}}>-{parseInt((props.product.price - props.product.discountedPrice)*100/(props.product.price))}%</h6>
                    </div>
                    :
                        <h3 className="mb-0">&#8377; {props.product.price}</h3>
                    } 
                {/* {props.product.isDiscounted === true ? discountPrice : originalPrice} */}
                {/* <p className="cardPrice m-0">{props.product.price} INR</p> */}
                {props.product.quantity > 0 && props.qtyInCart < props.product.quantity
                    ?
                    props.inCart 
                        ? 
                            <div className="qtyPicker mt-3">
                                <QuantityPicker id={props.product.id} productQuantity={props.product.quantity} /> 
                            </div> 
                        : 
                            <div role="button" className="btn p-1 mt-3 addBtn" onClick={addBtn}>
                                Add to Cart <img src={shoppingCartBtn} alt=""/>
                            </div>
                
                    :
                    <button className="btn p-1 mt-3 addBtn" disabled>
                        Out of Stock
                    </button>
                }
                {/* { JSON.stringify(props.user) !== "{}" && props.saved ? <button className="d-none" onClick={unSave}>Unsave</button> : null}
                { JSON.stringify(props.user) !== "{}" && props.saved === false ? <div role="button" className="btn d-none button-small btn-success" onClick={like}>Like</div>: null} */}
                
            </div>
        </div>
    );
    
}

const mapStateToProps = (state,ownProps) => {
    return {
        user : state.profile,
        inCart : state.cart.find(item=>item.id === ownProps.product.id) ? true : false,
        qtyInCart : state.cart.find(item=>item.id === ownProps.product.id) ? (state.cart.find(item=>item.id === ownProps.product.id)).qty : 0,
        saved : state.profile.wishlist && state.profile.wishlist.find(item => item.id === ownProps.product.id) ? true : false
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart : (item) => dispatch(addtoCart(item)),
        like : (item, id) => dispatch(addToWishlist(item,id)),
        updateQty : (id,qty) => dispatch(updateQty(id,qty)),
        unSave : (id) => dispatch(deleteFromWishlist(id))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ProductCard)
