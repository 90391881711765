import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Home from './Home/Home'
import './Landing.css'
import CategoryPage from './CategoryPage/CategoryPage'
import Contact from '../Contact/Contact'
import Account from './UserProfile/Account'
import Orders from './UserProfile/Orders/Orders'
import Addresses from './UserProfile/Addresses'
import PageNotFound from '../PageNotFound'
import ProductPage from './ProductPage/ProductPage'
import { connect } from 'react-redux'
import Footer from '../Footer'
import Privacy from './Privacy'
import DataDeletion from './DataDeletion'
import Terms from './Terms'
// import Membership from './UserProfile/Membership'
// import ReferAndEarn from './UserProfile/ReferAndEarn'

export class Landing extends Component {
    render() {
        const path = this.props.path
        return (
            <div id="landing">
                <Switch>
                    <Route exact path = {path} component = {Home} />
                    <Route path={`${path}/category/:category`} component = {CategoryPage} />
                    <Route path={`${path}/product/:pId`} component = {ProductPage} />
                    <Route path={`${path}/contact`} component = {Contact} />
                    <Route path={`${path}/privacy_policy`} component = {Privacy} />
                    <Route path={`${path}/data_deletion`} component = {DataDeletion} />
                    <Route path={`${path}/terms`} component = {Terms} />
                    <Route path={`${path}/account`}
                        render={props =>(
                            this.props.authenticated ? <Account /> : <Redirect to='/'/>
                        )}
                    />
                    <Route path={`${path}/orders`}
                        render={props =>(
                            this.props.authenticated ? <Orders /> : <Redirect to='/'/>
                        )}
                    />
                    <Route path={`${path}/addresses`}
                        render={props =>(
                            this.props.authenticated ? <Addresses /> : <Redirect to='/'/>
                        )}
                    />
                    {/* <Route path={`${path}/membership`} component={Membership} /> */}
                    {/* <Route path={`${path}/refer`} component={ReferAndEarn} /> */}
                    <Route component={PageNotFound} />
                </Switch>
                
                <div className="footer">
                    <Footer />
                </div>
            </div>   
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated : JSON.stringify(state.profile) === "{}" ? false : true
    }
}

export default connect(mapStateToProps,null)(Landing)
