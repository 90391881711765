import React, { Component } from 'react'
import {connect} from 'react-redux'
import FillAddress from './FillAddress'
import SavedAddresses from './SavedAddresses'
import arrow from '../../Icons/chevron-up.svg'
import { ProductCardCheckout } from './ProductCardCheckout'
import CouponCatalog from './CouponCatalog'
import url from '../../url.json'
import keys from '../../keys.json'
import { InputGroup } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import { setPaymentMode } from '../../store/actions/checkout/setPaymentMode'
import { setSodexoMode } from '../../store/actions/checkout/setSodexoMode'


export class Accordion extends Component {

    constructor(props){
        super(props);
        // this.state = {
        //     coupons : []
        // }
    }
    
    // componentDidMount(){
    //     console.log(this.props)
    //     fetch(`${url.url}/api/coupons/public`)
    //     .then(res=> res.json())
    //     .then(resJson => {
    //         // console.log("Coupons from DB",resJson.Items)
    //         this.setState({coupons : resJson.Items})
    //     })
    //     .catch(err => console.error("Error fetching coupons", err))
    // }

    onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        this.props.setCaptchaStatus(1)
    }


    onSodexoModeChange = (e) => {
        document.getElementById("sodexoModeError").style.display = 'none';
        this.props.setSodexoMode(e.target.value);
    }
    render() {
        return (
            <div className="accordion">
                <div className="card my-3 shadow-sm">
                    <div className="card-header">
                        <div className="d-flex justify-content-between" role="button" data-toggle="collapse" data-target="#cartOverview" aria-expanded="true" aria-controls="cartOverview">
                            <h4 className="mb-0">1. Cart Overview</h4>
                            <img src={arrow} alt=""/>
                        </div>
                    </div>
                    <div id="cartOverview" className="collapse show">
                        <div className="card-body">
                            {this.props.cart.map((item,index)=>{
                                return <ProductCardCheckout item={item} key={index}/>
                            })}
                            {/* <Products items = {this.props.cart} /> */}
                        </div>
                    </div>
                </div>

                <div className="card my-3 shadow-sm">
                    <div className="card-header">
                        <div className="d-flex justify-content-between" role="button" data-toggle="collapse" data-target="#addresses" aria-expanded="true" aria-controls="addresses">
                            <h4 className="mb-0">2. Select Delivery Address</h4>
                            <img src={arrow} alt=""/>
                        </div>
                    </div>
                    <div id="" className="collapse show">
                        <div className="card-body">
                            {typeof this.props.addresses === 'undefined' ? <FillAddress servicable={this.props.servicable} setServicable={this.props.setServicable}/> : <SavedAddresses />}
                        </div>
                    </div>
                </div>

                <div className="card my-3 shadow-sm">
                    <div className="card-header">
                        <div className="d-flex justify-content-between" role="button" data-toggle="collapse" data-target="#addresses" aria-expanded="true" aria-controls="addresses">
                            <h4 className="mb-0">3. Payment Mode</h4>
                            <img src={arrow} alt=""/>
                        </div>
                    </div>
                    <div id="" className="collapse show">
                        <div className="card-body">
                            <form className="d-flex flex-column justify-content-between">
                                <div className="row ml-2" role="button" onClick = {() => this.props.setPaymentMode("PREPAID")}>
                                    <input className="m-auto" type="radio" id="prepaid" name="paymentMode" 
                                        value="PREPAID" checked={this.props.paymentMode === "PREPAID"}
                                        />
                                    <div className="col">Pay Now</div>
                                </div>
                                <div className="row ml-2" role="button" onClick={() => {
                                    document.getElementById("captcha").style.display = "block"
                                    this.props.setPaymentMode("POSTPAID")}}
                                    >
                                    <input className="m-auto" type="radio" id="postpaid" name="paymentMode" 
                                        value="POSTPAID" checked={this.props.paymentMode === "POSTPAID"}
    
                                    />
                                    <div className="col d-flex flex-column">
                                        Cash On Delivery
                                        {this.props.paymentMode === "POSTPAID" ? <small><i className="text-info">( Additional &#8377;80 will apply for delivery using this method )</i></small> : null}
                                    </div>
                                    
                                </div>
                                
                                <div className="row ml-2" role="button" onClick = {() => this.props.setPaymentMode("SODEXO")}>
                                    <input className="m-auto" type="radio" id="sodexo" name="paymentMode" 
                                        value='SODEXO' checked={this.props.paymentMode === 'SODEXO'}
                                    />
                                    <div className="col d-flex flex-wrap">
                                        <div className="mr-1">Pay with Sodexo</div>
                                        <div className='d-none'>using
                                            <select id='sodexoMode' className="ml-1 form-select-sm" aria-label="sodexoMode" value={this.props.sodexoMode} onChange={this.onSodexoModeChange}>
                                                <option value={undefined}>select mode</option>
                                                <option value="PHONE_NUMBER">Phone Number</option>
                                                <option value="CARD">Card</option>
                                            </select>
                                        </div>
                                    </div>
                                   
                                </div>
                                <p className="ml-2 text-danger" style={{display:'none'}} id="sodexoModeError">* Please select a value</p>
                            </form>
                        </div>
                        <div className="mx-2 pr-2">
                                <p className="text-danger" style={{display:"none"}} id="captchaWarning">* Please verify below by checking the box</p>
                                <div className="w-75 px-2">
                                    <ReCAPTCHA
                                        id="captcha"
                                        style={{display:this.props.paymentMode === 'POSTPAID' ? null : 'none', transform:"scale(0.77)", "-webkit-transform":"scale(0.77)", "transform-origin":"0 0", "-webkit-transform-origin":"0 0"}}
                                        sitekey={keys.recaptcha}
                                        onChange={this.onCaptchaChange}
                                    />
                                </div>
                            </div>
                    </div>
                </div>

                <div className="card my-3 shadow-s">
                    <div className="card-header">
                        <div className="d-flex justify-content-between" role="button" data-toggle="collapse" data-target="#addMore" aria-expanded="true" aria-controls="addMore">
                            <h4 className="mb-0">4. Promo Code</h4>
                            <img src={arrow} alt=""/>
                        </div>
                    </div>
                    <div id="addMore" className="collapse show">
                        <div className="card-body">
                            <CouponCatalog 
                            applyCoupon={this.props.applyCoupon} couponState={this.props.couponState} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sodexoMode : state.checkout.sodexoMode,
        cart : state.cart,
        addresses : state.profile.addresses,
        subTotal : state.checkout.subTotal, 
        paymentMode : state.checkout.paymentMode,
        couponState : state.checkout.couponState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPaymentMode : (mode) => dispatch(setPaymentMode(mode)),
        setSodexoMode : (sodexoMode) => dispatch(setSodexoMode(sodexoMode))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Accordion)
