import React, { Component } from 'react'
import Products from '../Products/Products'
import url from '../../../url.json'
import { Spinner } from 'react-bootstrap';
import { func } from 'prop-types';

export class CategoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category : "",
            items : [],
            loading : true
        }
    }

    componentDidMount(){

        //for facebook pixel
        window.fbq('track', 'ViewContent', {content_category: this.props.match.params.category});
        let category = this.props.match.params.category
        console.log("Category formatted", category)
        this.setState({ category : this.props.match.params.category })
        fetch(`${url.url}/api/catalog/category/${this.props.match.params.category}`)
        .then(res => res.json())
        .then(resJson => {
            console.log("category products", resJson.Items)
            this.setState({ items : resJson.Items.sort(function(a,b){
                if ( a.name < b.name ){
                    return -1;
                  }
                  if ( a.name > b.name ){
                    return 1;
                  }
                  return 0;
            }), loading : false})
        })
        .catch(e => console.log("error while fetching products",e))
    }

    componentDidUpdate(){
        if (this.props.match.params.category !== this.state.category) {
            window.fbq('track', 'ViewContent', {content_category: this.props.match.params.category});
            this.setState({ category : this.props.match.params.category, loading : true })
            fetch(`${url.url}/api/catalog/category/${this.props.match.params.category}`)
            .then(res => res.json())
            .then(resJson => {
                console.log("category products", resJson.Items)
                this.setState({ items : resJson.Items.sort((function(a,b){
                    if ( a.name < b.name ){
                        return -1;
                      }
                      if ( a.name > b.name ){
                        return 1;
                      }
                      return 0;
                })), loading : false})
            })
            .catch(e => console.log("error while fetching products",e))
        }
    }
    render() {

        return (
            <div>
                {this.state.loading
                    ?
                    <div className="loadingDiv">
                        <Spinner className="spinner" animation="border" variant="success"/>
                    </div>
                    :
                    <div style={{paddingBottom:"100px"}}>
                        <div className="ml-md-4 pt-1">
                            <div className="my-3">
                                <h6 className="d-inline text-muted">Shop by Category &gt; </h6>
                                <h6 className="d-inline text-success">{this.props.match.params.category}</h6>
                            </div>
                            <h2 className="mb-3">{this.props.match.params.category}</h2>
                            <Products items={this.state.items} />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default CategoryPage
