import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux'
import { setCoupon } from '../../store/actions/checkout/setCoupon';
import Login from '../Side/Profile/Login/Login';

function Coupons(props) {
    const [show, setShow] = useState(false);
    const [userCoupon, setUserCoupon] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        props.applyCoupon()
    }, [props.subTotal])

    useEffect(()=>{
        props.authenticated && handleClose()
    }, [props.authenticated])

    const handleChange = (event) => {
        setUserCoupon(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setCoupon(userCoupon, ()=>null)
    }

    return (
        <div className="border-bottom pb-4">
            <Modal centered show={show} onHide={handleClose} animation={false}>
                <Login />
            </Modal>
            {/* <h5 className="mb-4">Coupon</h5> */}
            <form className="d-flex my-3 w-75" onSubmit={handleSubmit}>
                <input
                    className="rounded border-0 pl-2 mr-4"
                    style={{ backgroundColor : "#F5F5F5"}}
                    type="search"
                    placeholder="Promo Code"
                    value={userCoupon}
                    onChange={handleChange}
                    />
                {props.authenticated 
                    ?
                        <input type="submit" value="Apply" className="btn bg-transparent border rounded py-1 px-3"/>
                    :
                        <button className="bg-transparent text-muted border rounded py-1 px-3" disabled>Apply</button>
                }
            </form>
            
            {props.authenticated ? null : <p className="text-muted"><u className="text-info" role="button" onClick={handleShow}>Login</u> to enable promo codes</p>}
            {props.couponState === 0 ? <p className="text-danger">{props.couponMsg}</p> :  null}
            {props.couponState === 1 || props.couponState === 2
                ?
                    <div className="d-flex align-items-center">
                        <p className="mb-0 mr-2 text-success">Coupon Applied : {props.coupon}</p>
                        <button type="button" className="close" aria-label="Close" onClick={()=>props.setCoupon('', () => null )}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                : null
            }
            {props.couponState === 2 ? <small className="text-info">{props.couponMsg}</small> :  null}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        authenticated : JSON.stringify(state.profile) === "{}" ? false : true,
        cart : state.cart,
        subTotal : state.checkout.subTotal,
        couponMsg : state.checkout.couponMsg,
        couponState : state.checkout.couponState,
        coupon : state.checkout.coupon
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setCoupon : (cpn,cb) => dispatch(setCoupon(cpn,cb))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Coupons)